import * as React from "react";
import { useSelector } from "react-redux";
import { Import } from "../components/user/Import";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";

export const UserImport = () => {
  const isExpand = useSelector((state) => state.globalState.drawerExpand);
  const myLevel = useSelector((state) => state.globalState.userLevel);

  if (myLevel > 100) {
    return (
      <div className="grid-layout-container mt-2 p-8">
        Maaf Anda Tidak deperkenankan melihat halaman ini
      </div>
    );
  }

  return (
    <div
      className={
        isExpand
          ? "grid-layout-container mt-2 p-8 mb-5 expand"
          : "grid-layout-container mt-2 p-8 mb-5"
      }
    >
      <GridLayout
        gap={{
          rows: 20,
          cols: 5,
        }}
        rows={[
          {
            height: 'auto',
          },
        ]}
      >
        <GridLayoutItem row={2} col={1} className="grid-item">
          <Import />
        </GridLayoutItem>
      </GridLayout>
    </div>
  );
};
