import { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import readXlsxFile from "read-excel-file";
import emailValidator from "email-validator";
import { phone } from "phone";
import { get } from "lodash";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { skipToken } from "@reduxjs/toolkit/query";
import useAuth from "../../common/hooks/useAuth";
import { NumberCell, ActionCell } from "../custom-control/custom-cells";
import TheSpinner from "../generic/TheSpinner";
import BlockUI from "../generic/BlockUI";
import { Button } from "@progress/kendo-react-buttons";
import {
  useGetRolesQuery,
  useCreateUsersMutation,
} from "../../features/userSlice";
import { arrowRotateCcwIcon, uploadIcon } from "@progress/kendo-svg-icons";

export const Import = () => {
  const { user } = useAuth();
  const myLevel = useSelector((state) => state.globalState.userLevel);
  const inputFile = useRef(null);
  const [isImportValid, setIsImportValid] = useState(true);
  const [data, setData] = useState([]);
  const [isOnUpload, setIsOnUpload] = useState(false);
  const [createUsers, { isLoading: isCreating }] = useCreateUsersMutation();
  /* 
  const { data, isLoading, isSuccess, isError, error } = useGetAllUsersQuery(
    user.accessToken
      ? { currPage: curentPageValue, token: user.accessToken }
      : skipToken
  );
  */

  const {
    data: rolesDetail,
    isLoading: getRolesLoading,
    isSuccess: getRolesSuccess,
  } = useGetRolesQuery({ token: user.accessToken });

  /* 
  console.log("List User result", {
    data,
    isLoading,
    isSuccess,
    isError,
    error,
  }); 
  */

  const clearInput = () => {
    if (inputFile.current) {
      inputFile.current.value = "";
    }
    setData([]);
  };

  const uploadUsers = async () => {
    setIsOnUpload(true);
    const newUsers = [];
    for (const newUser of data) {
      newUsers.push({
        email: newUser.username,
        phoneNumber: newUser.phone,
        password: newUser.password,
        displayName: newUser.name,
        role: newUser.role,
        disabled: !newUser.enabled,
      });
    }

    const result = await createUsers({
      newUsers,
      token: user.accessToken,
    });

    // console.log("createUsers", { newUsers, result });

    if (get(result, "data.report", []).length > 0) {
      const newData = [];
      for (let itemData of data) {
        let newItem = { ...itemData };
        newItem.message = "Success";

        // console.log("new item 1", { newItem });

        let resultFilter = get(result, "data.report", []).filter(
          (x) => x.email === itemData.username
        );

        // console.log("new item 2", { resultFilter });
        if (resultFilter.length > 0) {
          newItem.message = resultFilter[0].message;
        }

        // console.log("new item 3", { newItem });

        newData.push(newItem);
      }

      setData(newData);
    }

    setIsOnUpload(false);
  };

  const handleFileInputChange = async (e) => {
    const file = e.target.files[0];
    // console.log("handleFileInputChange", { file });

    if (file && file.size > 5242835) {
      alert("file terlalu besar!");
      return;
    }
    setIsImportValid(true);
    readXlsxFile(file).then((rows) => {
      // console.log("rows data", { rows });
      let data = [];
      for (const row of rows) {
        let msg = "";
        let role = {};
        let phoneNumber = row[4];

        if (row[0] !== "No" && row[0] !== "") {
          if (!row[1] || row[1] === "" || !emailValidator.validate(row[1])) {
            msg = "Email Not Valid, ";
          }
          if (!row[2] || row[2] === "") {
            msg = msg + "Name Required, ";
          }
          if (!row[3] || row[3] === "") {
            msg = msg + "Role Required, ";
          }
          if (row[3] && row[3] !== "") {
            var existRole = get(rolesDetail, "result.results", []).filter(
              (role) => role.roleName.toLowerCase() === row[3].toLowerCase()
            );
            if (!existRole || existRole.length < 1) {
              if (row[3].toUpperCase() === 'SKIP'){
                role = {
                  id: -1,
                  level: -1,
                  roleName: 'SKIP',
                }
              } else {
                msg = msg + "Role Not Valid, ";
              }
            } else {
              if (existRole[0].level < myLevel) {
                msg = msg + "Cannot add Role above you, ";
              } else {
                role = existRole[0];
              }
            }
          }
          if (!phoneNumber || phoneNumber === "") {
            msg = msg + "Phone Required, ";
          }
          if (phoneNumber && phoneNumber !== "") {
            phoneNumber = phoneNumber.toString().startsWith("0")
              ? "+62" + phoneNumber.slice(1)
              : phoneNumber;
            const phoneValid = phone(phoneNumber);
            if (!phoneValid.isValid) {
              msg = msg + "Phone Number not valid, ";
            }
          }
          if (!row[5] || row[5] === "" || row[5].length < 6) {
            msg = msg + "Password Required and min 6 character. ";
          }

          data.push({
            no: row[0],
            username: row[1],
            name: row[2],
            role,
            phone: phoneNumber,
            password: row[5],
            enabled: get(row, '[6]', 'enabled').toLowerCase().includes('enable'),
            message: msg,
          });

          if (msg !== "") {
            setIsImportValid(false);
          }
        }
      }
      setData(data);
    });
  };

  // console.log("Data for this Impot page", { rolesDetail, data });

  const messageCell = (props) => {
    let message =
      props.dataItem.message === "" ? "Ok" : props.dataItem.message;

    if (message === "Success" || message === "Ok") {
      return <td className="text-success">{message}</td>;
    } else {
      return <td className="text-error">{message}</td>;
    }
  };

  const enabledCell = (props) => {
    if (props.dataItem.enabled) {
      return <td className="text-center"><span className="k-icon k-font-icon k-i-check-outline k-icon-xl text-green-500"></span></td>;
    } else {
      return <td className="text-center"> <span className="k-icon k-font-icon k-i-cancel k-icon-xl text-red-500"></span></td>;
    }
  };

  const renderContent = () => {
    return (
      <>
        <div className="k-mb-4">
          <div className="row k-mb-4 items-center">
            <div className="col col-2">
              <div className="k-label">Upload excel file</div>
            </div>
            <div className="col col-5">
              <input
                className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md"
                type="file"
                name="file"
                onChange={handleFileInputChange}
                accept=".xlsx"
                ref={inputFile}
              />
              <Button
                svgIcon={arrowRotateCcwIcon}
                onClick={clearInput}
                disabled={isOnUpload}
                className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md ml-2"
              ></Button>
            </div>
            <div className="col col-5">
              {isImportValid && data.length > 0 && (
                <Button
                  svgIcon={uploadIcon}
                  onClick={uploadUsers}
                  className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md ml-2"
                >
                  Upload
                </Button>
              )}
            </div>
          </div>
        </div>
        <hr className="full-width" />
        <div className="k-mt-10 ">
          <BlockUI blocking={isOnUpload || getRolesLoading} />
          <Grid data={data} className="h-auto">
            <GridColumn field="no" title="No." width={50} />
            <GridColumn field="name" title="Name Lengkap" />
            <GridColumn field="role.roleName" title="Role" />
            <GridColumn field="phone" title="Nomor Telepon" />
            <GridColumn field="username" title="Email" />
            <GridColumn field="password" title="Pasword" />
            <GridColumn field="enabled" title="Enabled" cell={enabledCell} />
            <GridColumn field="message" title="message" cell={messageCell} />
          </Grid>
        </div>
      </>
    );
  };

  return renderContent();
};
