import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import useAuth from "../common/hooks/useAuth";
import BlockUI from "../components/generic/BlockUI";
import {
  useUploadEmptyMapelsFileBlobMutation,
  useDownloadSoalsMutation,
} from "../features/soalFinderSlice";
import { showNotification } from "../features/globalStateSlice";
import { NOTIFICATION_STYLE } from "../components/generic/wd-notification";
import { EmptyMapelListing } from "../components/soal/EmptyMapelListing";

export const SoalEmptyMapel = () => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const PAGE_SIZE = 2147483647;

  const isExpand = useSelector((state) => state.globalState.drawerExpand);
  const [uploadEmptyMapelsFileBlob, { isLoading: isLoadingUploadEmptyMapelsFileBlob }] =
    useUploadEmptyMapelsFileBlobMutation();
  const [downloadSoals, { isLoading: isLoadingDownloadSoals }] =
    useDownloadSoalsMutation();
  const [base64Image, setBase64Image] = useState("");
  const [fileName, setFileName] = useState("");
  
  const [pageProgress, setPageProgress] = useState(false);

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const handleFileInputChange = async (e) => {
    const file = e.target.files[0];
    // console.log("handleFileInputChange", { file });

    if (file && file.size > 5242835) {
      alert("file terlalu besar!");
      return;
    }

    getBase64(file)
      .then(async (result) => {
        // console.log("get base64", result);
        setBase64Image(result);
        setFileName(file.name);

        await uploadEmptyMapelsFileBlob({
          fileName: file.name,
          serializedWord: result ? result.split(",")[1] : undefined,
          token: user.accessToken,
        });

        dispatch(
          showNotification({
            style: NOTIFICATION_STYLE.SUCCESS,
            message: `Mapel soal kosong telah disimpan`,
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDownloadTemplate = async () => {
    setPageProgress(true);
    await downloadSoals({ 
      fileName: 'MapelKosong.xlsx', 
      mataPelajaran: 'NULL',
      page: 1,
      paging: PAGE_SIZE,
      token: user.accessToken,
      userId: user.uid,
    });
    setPageProgress(false);
  };

  return (
    <div
      className={
        isExpand ? "grid-layout-container expand" : "grid-layout-container"
      }
    >
      <BlockUI blocking={(
        isLoadingDownloadSoals || 
        isLoadingUploadEmptyMapelsFileBlob ||
        pageProgress
      )} />
      <GridLayout
        gap={{
          rows: 2,
          cols: 1,
        }}
        rows={[
          {
            height: 300,
          },
          {
            height: "auto",
          },
        ]}
      >
        <GridLayoutItem row={1} col={1} className="grid-item">
          <div className="px-11 mt-6">
            <div className="subtitle">
              <h3>Export dan Import</h3>
            </div>
            <div>
              <div className="row k-mb-4 items-center">
                <div className="col col-4">
                  <div className="k-label">Pilih file</div>
                </div>
                <div className="col col-8">
                <input
                    className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md"
                    type="file"
                    name="file"
                    onChange={handleFileInputChange}
                    accept=".xlsx"
                  />
                </div>
              </div>

              <hr className="full-width" />

              <div className="row k-mb-4 k-mt-10 items-center">
                <div className="col text-right ">
                  <Button
                    onClick={handleDownloadTemplate}
                    className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md px-5 mx-1"
                  >
                    Download Soals
                  </Button>
                </div>
              </div>
              
            </div>
          </div>
        </GridLayoutItem>
        <GridLayoutItem row={2} col={1} className="grid-item clear-bg topbrr mb-8">
          <EmptyMapelListing />
        </GridLayoutItem>
      </GridLayout>
    </div>
  );
};
