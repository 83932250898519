import React from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { useForm } from "react-hook-form";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { useLocation, useNavigate } from "react-router-dom";
import TheSpinner from "../components/generic/TheSpinner";
import useAuth from "../common/hooks/useAuth";

export const SignIn = () => {
  const [paswordType, setPasswordType] = React.useState("password");

  const handleClick = () => {
    if (paswordType === "password") {
      setPasswordType("text");
    } else if (paswordType === "text") {
      setPasswordType("password");
    }
  };

  // user from auth
  const { loginUser, user, isLoading, authError } = useAuth();

  // react hook form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // redirect
  let navigate = useNavigate();
  let location = useLocation();

  let from = location.state?.from?.pathname || "/home/dashboard";

  // redireact user if logged in
  if (user?.email) {
    navigate(from, { replace: true });
  }

  // subnitting register form
  const onSubmit = (data) => {
    loginUser(data.email, data.password, navigate, location);
    reset({});
  };

  return (
    <div className="App">
      <div className="sign-in-page flex justify-center items-center">
        {isLoading ? (
          <TheSpinner />
        ) : (
          <div className="sign-in-wrapper flex-row rounded-xl relative">
            <div className="sign-in-hello h-full rounded-l-lg flex justify-end items-center">
              <img
                src={require("../assets/si-boba-cover.jpeg")}
                alt={"si boba"}
                className="si-boba-img"
              />
            </div>
            <div className="inputs-wrapper p-5">
              <h2 className="text-3xl font-bold tracking-wide leading-15 mb-4 text-[#2F4F2A]">
                Login
              </h2>
              <Form
                onSubmit={handleSubmit(onSubmit)}
                render={(formRenderProps) => (
                  <FormElement
                    style={{
                      maxWidth: 650,
                    }}
                  >
                    <fieldset className={"k-form-fieldset"}>
                      <div className="mb-3">
                        <Field
                          name={"email"}
                          type={"email"}
                          component={Input}
                          label={"Email"}
                          {...register("email", { required: true })}
                        />
                      </div>
                      <div className="mb-3 flex relative">
                        <Field
                          name={"password"}
                          type={paswordType}
                          component={Input}
                          label={"Password"}
                          {...register("password", { required: true })}
                        />
                        <span
                          className="k-icon k-i-preview password-icon"
                          onClick={handleClick}
                        ></span>
                      </div>
                    </fieldset>

                    <div className="k-form-buttons">
                      <Button type={"submit"} themeColor={"primary"}>
                        Masuk
                      </Button>
                    </div>
                  </FormElement>
                )}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
