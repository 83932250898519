import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import { get } from "lodash";
import {
  drawerExpand,
  fullWidthModeEventChanges,
  noHeaderModeEventChanges,
} from "../features/globalStateSlice";
import { useGetPreviewKeysQuery } from "../features/soalEditorSlice";
import useAuth from "../common/hooks/useAuth";
import BlockUI from "../components/generic/BlockUI";
import { useGetSettingQuery } from "../features/settingSlice";

export const SoalPreview = () => {
  // const isExpand = useSelector((state) => state.globalState.drawerExpand);
  let { id } = useParams();
  const dispatch = useDispatch();
  const { user } = useAuth();

  useEffect(() => {
    dispatch(drawerExpand(true));
    dispatch(fullWidthModeEventChanges(true));
    dispatch(fullWidthModeEventChanges(true));
    dispatch(noHeaderModeEventChanges(true));
  }, []);

  const {
    data: soalPreviewKeys,
    // isLoading: getSoalHtmlLoading,
    isSuccess: getSoalPreviewKeysSuccess,
  } = useGetPreviewKeysQuery(
    id && user ? { kodeSoal: id, token: user.accessToken } : skipToken
  );

  const { data: getSettingData } = useGetSettingQuery(
    user.accessToken ? user.accessToken : skipToken
  );

  return (
    <>
      <BlockUI blocking={!soalPreviewKeys} className="w-100 h-100" />
      {getSoalPreviewKeysSuccess &&
        soalPreviewKeys &&
        getSettingData &&
        soalPreviewKeys.result && (
          <iframe
            className="w-100 h-100"
            src={`${get(getSettingData, "previewUrl", false)}/?viewkonten=${
              soalPreviewKeys.result.data1
            }&dataexp=${soalPreviewKeys.result.data2}&uid=${
              soalPreviewKeys.result.data3
            }`}
            title="SoalPreview"
          ></iframe>
        )}
      {getSoalPreviewKeysSuccess &&
        soalPreviewKeys &&
        !soalPreviewKeys.result && (
          <div className="flex justify-center items-center h-100">
            <h2>Maaf Preview gagal</h2>
          </div>
        )}
    </>
  );
};
