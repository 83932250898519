import { useState } from "react";
import { includes, omit, set } from "lodash";
import { useLocation, useNavigate, Outlet } from "react-router-dom";
import {
  Drawer,
  DrawerContent,
  DrawerItem,
} from "@progress/kendo-react-layout";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@progress/kendo-react-buttons";
import { ProfileMenu } from "./dashboard/ProfileMenu";
import { drawerExpand } from "../features/globalStateSlice";
import { WdNotification } from "./generic/wd-notification";
import { SoalStep } from "../components/dashboard/SoalStep";

export const menus = [
  { separator: true },
  {
    text: "Dashboard",
    selected: true,
    route: "/home/dashboard",
    icon: "k-icon k-i-grid",
  },
  {
    text: "Soal",
    selected: false,
    route: "#",
    icon: "k-icon k-i-table-align-top-left",
    children: [
      {
        text: "Pembuatan Soal",
        selected: false,
        route: "/home/exam/new-soal",
        icon: "k-icon k-i-track-changes-enable",
        onlyShowFor: ["0", "1"],
      },
      {
        text: "Daftar Soal",
        selected: false,
        route: "/home/exam/daftar-soal",
        icon: "k-icon k-i-aggregate-fields",
        onlyShowFor: ["0", "1"],
      },
      {
        text: "Revisi Soal",
        selected: false,
        route: "/home/exam/revisi-soal",
        icon: "k-icon k-i-preview",
      },
/*
      {
        text: "Alur Kerja",
        selected: false,
        route: "/home/exam/revisi-soal-kanban",
        icon: "k-icon k-i-categorize",
      },
*/
      {
        text: "Alokasi Revisi Soal",
        selected: false,
        route: "/home/exam/alokasi-revisi",
        icon: "k-icon k-i-track-changes-accept-all",
        onlyShowFor: ["0", "1"],
      },
      {
        text: "Batch Upload Alokasi Revisi",
        selected: false,
        route: "/home/exam/batch-upload-alokasi-revisi",
        icon: "k-icon k-i-group-collection",
        onlyShowFor: ["0", "1"],
      },
      {
        text: "Batch Upload Soal",
        selected: false,
        route: "/home/exam/batch-upload-soal",
        icon: "k-icon k-i-upload",
        onlyShowFor: ["0", "1"],
      },
      {
        text: "Soal Mapel Kosong",
        selected: false,
        route: "/home/exam/empty-mapel-soal",
        icon: "k-icon k-i-filter-sort-asc-sm",
        onlyShowFor: ["0", "1"],
      },
      // {
      //   text: "Batch Upload Atribut Soal",
      //   selected: false,
      //   route: "/home/exam/batch-upload-soal-attribute",
      //   icon: "k-icon k-i-filter-sort-asc-sm",
      //   onlyShowFor: ["0", "1"],
      // },
    ],
  },
  { separator: true },
  {
    text: "Users",
    selected: false,
    route: "/home/search-user",
    icon: "k-icon k-i-user",
    onlyShowFor: ["0", "1"],
  },
  {
    text: "Transfer Soal MAHIR",
    selected: false,
    route: "/home/transfer-soal",
    icon: "k-icon k-i-link-horizontal k-i-hyperlink",
    onlyShowFor: ["0", "1"],
  },
  {
    text: "Transfer Soal Ke MAHIR",
    selected: false,
    route: "/home/transfer-soal-to-mahir",
    icon: "k-icon k-i-link-horizontal k-i-hyperlink",
    onlyShowFor: ["0", "1"],
  },
];

export const DrawerContainer = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  // const [expanded, setExpanded] = useState(true);
  // const isExpand = useSelector((state) => state.globalState.drawerExpand);
  const dispatch = useDispatch();
  const myRoleId = useSelector((state) => state.globalState.roleId);
  const isExpand = useSelector((state) => state.globalState.drawerExpand);
  const fullWidthMode = useSelector((state) => state.globalState.fullWidthMode);
  const noHeaderMode = useSelector((state) => state.globalState.noHeaderMode);

  const handleClick = () => {
    // setExpanded(!expanded);
    // console.log("yang dikirim", expanded);
    dispatch(drawerExpand(!isExpand));
  };

  const onSelect = (e) => {
    navigate(e.itemTarget.props.route);
  };

  const setSelectedItem = (pathName) => {
    let result = "";
    menus.forEach((item) => {
      if (item.route === pathName) {
        result = item.text;
      }

      if (item.children && item.children.length > 0) {
        item.children.forEach((child) => {
          if (child.route === pathName) result = child.text;
        });
      }
    });

    return result;
  };

  const selected = setSelectedItem(location.pathname);

  let userMenu = [];
  for (const menu of menus) {
    if (menu.onlyShowFor) {
      if (includes(menu.onlyShowFor, myRoleId)) {
        userMenu.push(omit(menu, ["children"]));
      }
    } else {
      userMenu.push(omit(menu, ["children"]));
    }

    if (menu.children && menu.children.length > 0) {
      let submenus = [];
      for (const subMenu of menu.children) {
        if (subMenu.onlyShowFor) {
          if (includes(subMenu.onlyShowFor, myRoleId)) {
            submenus.push(subMenu);
          }
        } else {
          submenus.push(subMenu);
        }
      }
      set(userMenu[userMenu.length - 1], "children", submenus);
    }
  }

  /*
  console.log("drawer container state is", {
    isExpand,
    selected,
    pathname: location.pathname,
    // flattenDeep: _.flatten(menus, 2),
    userMenu,
    myRoleId,
  });
  */

  const CustomItem = (props) => {
    return (
      <>
        <DrawerItem {...props}>
          <span
            className={`${props.icon} k-font-icon k-i-k-icon `}
            role="presentation"
          ></span>
          <span className="k-item-text">{props.text}</span>
        </DrawerItem>{" "}
        {props.children && props.children.length > 0 && (
          <Drawer
            expanded={!isExpand}
            position={"start"}
            mode={"push"}
            width={220}
            items={props.children.map((item) => ({
              ...item,
              selected: location.pathname === item.route,
            }))}
            onSelect={onSelect}
            className="drawer level2"
          ></Drawer>
        )}
      </>
    );
  };

  return (
    <div>
      <div>
        <div className={noHeaderMode ? 'hidden': 'brand-container'}>
          <label>Bank Soal Balai</label>
        </div>

        <Drawer
          expanded={!isExpand}
          position={"start"}
          mode={"push"}
          width={250}
          items={userMenu.map((item) => ({
            ...item,
            selected: item.text === selected,
          }))}
          onSelect={onSelect}
          className={noHeaderMode ? 'drawer no-head': 'drawer'}
          item={CustomItem}
        >
          <DrawerContent>
            {props.children}
            <Outlet />
          </DrawerContent>
        </Drawer>
      </div>

      <div
        className={noHeaderMode ? 'hidden' : !isExpand ? "custom-toolbar" : "custom-toolbar fullwidth"}
      >
        <div>
          {!fullWidthMode && (
            <Button
              icon="menu"
              onClick={handleClick}
              className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md"
            />
          )}

          <span className="overview">
            {selected === "Dashboard" ? "" : selected}
          </span>
        </div>
        {fullWidthMode && <SoalStep />}

          <div className={noHeaderMode ? 'hidden': ''}>
            <div className="right-widget">
              <div className="alert-container">
                <ProfileMenu />
              </div>
            </div>
          </div>
        
      </div>
      <WdNotification />
    </div>
  );
};
