import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { SoalBaruFullComponent } from "../components/exam/SoalBaruFullComponent";
import {
  drawerExpand,
  fullWidthModeEventChanges,
} from "../features/globalStateSlice";

export const SoalFullWidth = () => {
  // const isExpand = useSelector((state) => state.globalState.drawerExpand);
  let { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(drawerExpand(true));
    dispatch(fullWidthModeEventChanges(true));
  }, []);

  return (
    <div className="grid-layout-container mt-2 expand">
      <GridLayout
        gap={{
          rows: 1,
          cols: 1,
        }}
        rows={[
          {
            height: "auto",
          },
        ]}
      >
        <GridLayoutItem row={1} col={1} className="grid-item">
          <SoalBaruFullComponent kodeSoal={id} mode="edit" />
        </GridLayoutItem>
      </GridLayout>
    </div>
  );
};
