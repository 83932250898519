import { useState, useEffect } from "react";
import { get } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { skipToken } from "@reduxjs/toolkit/query";
import useAuth from "../../common/hooks/useAuth";
import BlockUI from "../generic/BlockUI";
import {
  useGetPagingStatusQuery,
  useGetListSoalQuery,
} from "../../features/soalFinderSlice";
import { NumberCell } from "../custom-control/custom-cells";
import { CellRender, RowRender } from "./CustomCellRenderer";
// import { MultiSelectDropdownCell } from "./CustomCells";
import { useCreateAlokasiSoalUsersMutation } from "../../features/alokasiRevisiSlice";
import { useGetAllUsersQuery } from "../../features/userSlice";
import { showNotification } from "../../features/globalStateSlice";
import { NOTIFICATION_STYLE } from "../generic/wd-notification";
import { Assignee } from "../exam/Assignee";

const editField = "inEdit";

export const Listing = ({ startFiltering, filterParam }) => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const PAGE_SIZE = 10;
  const [curentPageValue, setCurrentPageValue] = useState(1);
  // soal and user selection
  const [isDirty, setDirty] = useState(false);
  const [soals, setSoals] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [pageProgress, setPageProgress] = useState(false);

  const {
    data: getUsersData,
    isFetching: getUsersLoading,
    isSuccess: isGetUsersSuccess,
    isError: isGetUsersError,
    error: getUsersError,
  } = useGetAllUsersQuery(
    user.accessToken ? { currPage: -1, token: user.accessToken } : skipToken
  );

  const [createAlokasiSoalUsers, { isFetching: createAlokasiSoalUserLoading }] =
    useCreateAlokasiSoalUsersMutation();

  const {
    data: getListSoalData,
    isFetching: getlistSoalLoading,
    isSuccess: isGetListSoalSuccess,
    isError: isGetListSoalError,
    error: getListSoalError,
  } = useGetListSoalQuery(
    user.accessToken
      ? {
          kodeSoal: filterParam.kodeSoal ?? "",
          mataPelajaran: filterParam.mataPelajaran ?? "",
          indikator: filterParam.indikator ?? "",
          page: curentPageValue,
          paging: PAGE_SIZE,
          token: user.accessToken,
          userId: user.uid,
          includeAlokasi: true,
        }
      : skipToken
  );

  const {
    data: getPagingStatusData,
    isFetching: getPagingStatusLoading,
    isSuccess: isGetPagingStatusSuccess,
    isError: isGetPagingStatusError,
    error: getPagingStatusError,
  } = useGetPagingStatusQuery(
    user.accessToken
      ? {
          kodeSoal: filterParam.kodeSoal ?? "",
          mataPelajaran: filterParam.mataPelajaran ?? "",
          indikator: filterParam.indikator ?? "",
          paging: PAGE_SIZE,
          token: user.accessToken,
          userId: user.uid,
        }
      : skipToken
  );

  useEffect(() => {
    //
  }, [curentPageValue, startFiltering]);

  useEffect(() => {
    if (getListSoalData && getListSoalData.result) {
      const soalList = getListSoalData.result.Soals ?? [];
      const tempUsers = get(getUsersData, "result.users", []);

      if (soalList) {
        const mappedSoals = soalList.map((item) => {
          var uids = item.AlokasiUsers;

          var result = {
            ...item,
            users: tempUsers,
            selectedusers: item.AlokasiUsers.map((item) => item.UserId),
            [editField]: undefined,
          };

          return result;
        });
        setSoals(mappedSoals);
      }
    }
  }, [getListSoalData, getUsersData]);

  // user and soal selection functions
  const saveChanges = () => {
    setDirty(false);
  };

  const cancelChanges = () => {
    setDirty(false);
  };

  const itemChange = (event) => {
    const values = event.value;
    const uids = values.map((item) => item.uid);

    setSelectedUsers(uids);
    setDirty(true);
  };

  const enterEdit = (dataItem, field) => {
    setSelectedUsers(dataItem.selectedusers ?? []);
    const newData = soals.map((item) => ({
      ...item,
      users: getUsersData.result.users,
      [editField]: item.KodeSoal === dataItem.KodeSoal ? field : undefined,
    }));
    setSoals(newData);
  };

  const exitEdit = (dataItem) => {
    const newData = soals.map((item) => {
      if (item.KodeSoal === dataItem.KodeSoal) {
        return {
          ...item,
          selectedusers: selectedUsers,
          [editField]: undefined,
        };
      }

      return {
        ...item,
        [editField]: undefined,
      };
    });
    setSoals(newData);
    setSelectedUsers([]);
  };

  const pageChange = (event) => {
    if (!isDirty) {
      const currPage = event.page.skip / PAGE_SIZE + 1;
      setCurrentPageValue(currPage);
      return;
    }

    if (window.confirm("Changes will be discarded, proceed?")) {
      const currPage = event.page.skip / PAGE_SIZE + 1;
      setCurrentPageValue(currPage);
      setDirty(false);
    }
  };

  const handleAlokasiClick = async () => {
    if (!isDirty) {
      return;
    }
    setPageProgress(true);

    const alokasiSoal = [];

    for (var i = 0; i < soals.length; i++) {
      var soal = soals[i];

      if (soal.selectedusers) {
        alokasiSoal.push({
          KodeSoal: soal.KodeSoal,
          Users: soal.selectedusers.map((item) => ({
            UserId: item,
          })),
        });
      }
    }

    await createAlokasiSoalUsers({ alokasiSoal, token: user.accessToken });
    setPageProgress(false);
    setDirty(false);

    dispatch(
      showNotification({
        style: NOTIFICATION_STYLE.SUCCESS,
        message: `Alokasi revisi soal telah disimpan`,
      })
    );
  };

  const customCellRender = (td, props) => (
    <CellRender
      originalProps={props}
      td={td}
      enterEdit={enterEdit}
      editField={editField}
      users={get(getUsersData, "result.Users", [])}
    />
  );

  const customRowRender = (tr, props) => (
    <RowRender
      originalProps={props}
      tr={tr}
      exitEdit={exitEdit}
      editField={editField}
      users={get(getUsersData, "result.Users", [])}
    />
  );

  const assigneeChange = (event, kodeSoal) => {
    console.log("assignee Change", { event, kodeSoal });

    const newData = soals.map((item) => {
      if (item.KodeSoal === kodeSoal) {
        const selectedUsers = [];
        for (const user of event.data) {
          selectedUsers.push(user.uid);
        }

        return {
          ...item,
          selectedusers: selectedUsers,
          [editField]: undefined,
        };
      }

      return {
        ...item,
        [editField]: undefined,
      };
    });
    setSoals(newData);
    setDirty(true);
  };

  const assigneeCell = (props) => {
    console.log("assigneeCell dataItem", { dataItem: props.dataItem });
    const userAlloc = [];
    for (var user of props.dataItem.selectedusers) {
      userAlloc.push({ UserId: user });
    }

    return (
      /*
      <UserRoleSelector props={props} />
      */
      <Assignee
        usersAlloc={userAlloc}
        vertical={true}
        assigneeChange={(e) => assigneeChange(e, props.dataItem.KodeSoal)}
      />
    );
  };

  return (
    <div className="px-11 mt-6">
      <BlockUI
        blocking={
          getlistSoalLoading ||
          getPagingStatusLoading ||
          getUsersLoading ||
          createAlokasiSoalUserLoading ||
          pageProgress
        }
      />
      <div className="subtitle">
        <h3>Daftar Soal</h3>
      </div>
      <div>
        {isGetListSoalSuccess &&
          getListSoalData &&
          getListSoalData.result &&
          isGetPagingStatusSuccess &&
          getPagingStatusData &&
          getPagingStatusData.result &&
          isGetUsersSuccess &&
          getUsersData &&
          getUsersData.result && (
            <Grid
              data={soals}
              total={get(getPagingStatusData, "result.JumlahSoal", 0)}
              take={PAGE_SIZE}
              skip={(curentPageValue - 1) * PAGE_SIZE}
              onItemChange={itemChange}
              cellRender={customCellRender}
              rowRender={customRowRender}
              editField={editField}
              pageable={{
                buttonCount: 10,
              }}
              onPageChange={pageChange}
              className="h-auto"
            >
              <GridColumn
                cells={{ data: NumberCell }}
                title="No."
                width={50}
                editable={false}
              />
              <GridColumn
                field="KodeSoal"
                title="Kode Soal"
                width={250}
                editable={false}
              />
              <GridColumn
                field="MataPelajaran"
                title="Mata Pelajaran"
                width={150}
                editable={false}
              />
              <GridColumn
                field="Indikator"
                title="Indikator"
                editable={false}
              />
              {/*
              <GridColumn
                field="users"
                title="Users"
                cell={MultiSelectDropdownCell}
              />
              */}
              <GridColumn field="users" title="Users" cell={assigneeCell} />
            </Grid>
          )}
      </div>
      <div className="row k-mt-10">
        <div className="col col-12">
          {isGetListSoalSuccess &&
            getListSoalData &&
            getListSoalData.result &&
            getPagingStatusData &&
            getPagingStatusData.result &&
            isGetUsersSuccess &&
            getUsersData &&
            getUsersData.result && (
              <div className="">
                <Button
                  onClick={handleAlokasiClick}
                  className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md px-5 mx-1 mb-12"
                >
                  Alokasi Soal
                </Button>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};
