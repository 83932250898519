import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const alokasiRevisiSlice = createApi({
  reducerPath: 'alokasiRevisiApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_API_URL}/soal-revisi` }),
  tagTypes: ['alokasi_revisi'],
  endpoints: (builder) => ({
    createAlokasiSoalUsers: builder.mutation({
      query: ({ alokasiSoal, token }) => ({
        url: `/save-alokasi`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: {
          alokasiSoal: alokasiSoal,
        },
      }),
      providesTags: ['alokasi_revisi'],
    }),
    downloadTemplateAlokasiRevisi: builder.mutation({
      queryFn: async ({ fileName, token }, api, extraOptions, baseQuery) => {
        const result = await baseQuery({
          url: '/download-template-alokasi-revisi',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          method: 'GET',
          cache: 'no-cache',
          responseHandler: ((response) => response.blob())
        })
        var hiddenElement = document.createElement('a');
        var url = window.URL || window.webkitURL;
        var blobData = url.createObjectURL(result.data);
        hiddenElement.href = blobData;
        hiddenElement.target = '_blank';
        hiddenElement.download = fileName;
        hiddenElement.click();
        return { data: null }
      }
    }),
    previewBatchFileBlob: builder.mutation({
      query: ({ fileName, serializedWord, token }) => ({
        url: '/preview-batch-upload-file',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: { fileName, serializedWord },
      }),
      invalidatesTags: ['alokasi_revisi'],
    }),
  }),
});

export const {
  useCreateAlokasiSoalUsersMutation,
  useDownloadTemplateAlokasiRevisiMutation,
  usePreviewBatchFileBlobMutation
} = alokasiRevisiSlice;
