import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AuthProvider from './common/context/AuthProvider';
import PrivateRoute from './common/context/PrivateRoute';

import { SignIn } from './pages/SignIn.jsx';
import { SignUp } from './pages/SignUp.jsx';
import { Home } from './pages/Home.jsx'
import { Dashboard } from './pages/Dashboard.jsx'

import { UserManage } from './pages/UserManage.jsx';
import { UserSearch } from './pages/UserSearch.jsx';
import { UserImport } from './pages/UserImport.jsx';
import { SoalBaruPage } from './pages/SoalBaruPage.jsx';
import { SoalDaftar } from './pages/SoalDaftar.jsx';
import { SoalRevisi } from './pages/SoalRevisi.jsx';
import { ExamDetail } from './pages/ExamDetail.jsx';
import { SoalAlokasiRevisi } from './pages/SoalAlokasiRevisi.jsx';
import { SoalAlokasiRevisiBatchUpload } from './pages/SoalAlokasiRevisiBatchUpload.jsx';
import { SoalEmptyMapel } from './pages/SoalEmptyMapel.jsx';
import { TransferSoal } from './pages/TransferSoal.jsx';
import { TransferSoalToMahir } from './pages/TransferSoalToMahir.jsx';
import { TestPage } from './pages/TestPage.jsx';
import { SoalBatchUpload } from './pages/SoalBatchUpload.jsx';
// import { SoalAttributeBatchUpload } from './pages/SoalAttributeBatchUpload.jsx';
import { SoalFullWidth } from './pages/SoalFullWidth'
import { SoalRevisiKanban } from './pages/SoalRevisiKanban'
import { SoalPreview } from './pages/SoalPreview';
/*
import { About } from './pages/About.jsx'
import { PerformanceAndSales } from './pages/PerformanceAndSales.jsx'
import { Products } from './pages/Products.jsx';
import { Settings } from './pages/Settings.jsx';
import { TransferSoal } from '.pages/TransferSoal.jsx';
import { Account } from './components/settings/Account.jsx';
import { Billing } from './components/settings/Billing.jsx';
import { Notifications } from './components/settings/Notifications.jsx';
*/


const App = () => {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/home" element={<PrivateRoute><Home /></PrivateRoute>}>
            <Route path="/home/dashboard" element={<Dashboard />} />
            {/*
            <Route path="/home/about" element={<About />} />
            <Route path="/home/performance-and-sales" element={<PerformanceAndSales />} />
            <Route path="/home/products" element={<Products />} />
            <Route path="/home/settings" element={<Settings />} />
            <Route path="/home/account" element={<Account />} />
            <Route path="/home/billing" element={<Billing />} />
            <Route path="/home/notifications" element={<Notifications />} />
            */}
            <Route path="/home/search-user" element={<UserSearch />} />
            <Route path="/home/manage-user/:id" element={<UserManage />} />
            <Route path="/home/create-user" element={<UserManage />} />
            <Route path="/home/import-user" element={<UserImport />} />
            <Route path="/home/exam/new-soal" element={<SoalBaruPage />} />
            <Route path="/home/exam/daftar-soal" element={<SoalDaftar />} />
            <Route path="/home/exam/revisi-soal" element={<SoalRevisi />} />
            <Route path="/home/exam/revisi-soal-kanban" element={<SoalRevisiKanban />} />
            <Route path="/home/exam/batch-upload-soal" element={<SoalBatchUpload />} />
            {/* <Route path="/home/exam/batch-upload-soal-attribute" element={<SoalAttributeBatchUpload />} /> */}
            <Route path="/home/exam/detail/:name" element={<ExamDetail />} />
            <Route path="/home/exam/alokasi-revisi" element={<SoalAlokasiRevisi />} />
            <Route path="/home/exam/batch-upload-alokasi-revisi" element={<SoalAlokasiRevisiBatchUpload />} />
            <Route path="/home/exam/empty-mapel-soal" element={<SoalEmptyMapel />} />
            <Route path="/home/transfer-soal" element={<TransferSoal />} />
            <Route path="/home/transfer-soal-to-mahir" element={<TransferSoalToMahir />} />
            <Route path="/home/exam/full-width/:id" element={<SoalFullWidth />} />
            <Route path="/home/exam/preview/:id" element={<SoalPreview />} />
            <Route path="/home/test" element={<TestPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
