import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const userSlice = createApi({
  reducerPath: 'user',
  baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_API_URL}/user` }),
  tagTypes: ['user_list', 'user_detail'],
  endpoints: (builder) => ({
    getAllUsers: builder.query({
      query: ({ currPage = -1, keywords = '', token }) => ({
        url: `/get-all`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: { page: currPage, keywords },
      }),
      providesTags: ['user_list'],
    }),
    getUser: builder.query({
      query: ({ id, token }) => ({
        url: `/${id}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      providesTags: ['user_detail'],
    }),
    getMe: builder.query({
      query: (token) => ({
        url: `/me`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      providesTags: ['user_detail'],
    }),
    getRoles: builder.query({
      query: ({ token }) => ({
        url: `/get-roles`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    createUser: builder.mutation({
      query: ({ email, phoneNumber, password, displayName, photoExtension, disabled, photoChunk, meta, role, token }) => ({
        url: '/create',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: { email, phoneNumber, password, displayName, photoExtension, disabled, photoChunk, meta, role },
      }),
      invalidatesTags: ['user_list'],
    }),
    deleteUser: builder.mutation({
      query: ({ id, token }) => ({
        url: `/delete/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      invalidatesTags: ['user_list'],
    }),
    updateUser: builder.mutation({
      query: ({ id, email, phoneNumber, password, displayName, photoExtension, disabled, photoChunk, meta, role, token }) => ({
        url: '/update',
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: { id, email, phoneNumber, password, displayName, photoExtension, disabled, photoChunk, meta, role },
      }),
      invalidatesTags: ['user_list', 'user_detail'],
    }),
    createUsers: builder.mutation({
      query: ({ newUsers, token }) => ({
        url: '/creates',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: { data: newUsers },
      }),
      invalidatesTags: ['user_list'],
    }),
  }),
});

export const {
  useGetAllUsersQuery,
  useGetUserQuery,
  useLazyGetUserQuery,
  useGetRolesQuery,
  useCreateUserMutation,
  useCreateUsersMutation,
  useDeleteUserMutation,
  useUpdateUserMutation,
  useGetMeQuery,
} = userSlice;
