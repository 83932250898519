import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
// import { kodeSoalChanges, mapelChanges } from "../../features/globalStateSlice";

export const TransferCriteria = ({
  updateKodeSoal,
  updateMataPelajaran,
  checkSoalClick,
}) => {
  const handleCekSoalClick = () => {
    checkSoalClick();
  };

  /*
  console.log("Transfer criteria value", {
    kriteriaSoalChangesGlobal,
    kriteriaSoalValue,
    kodeKisiKisiValue,
    kodeKisiKisiChangesGlobal,
  });
  */

  return (
    <div className="px-11 mt-6">
      <div className="subtitle">
        <h3>Filter Soal</h3>
      </div>
      <div>
        <div className="row k-mb-4 items-center">
          <div className="col col-3">
            <div className="k-label">Kode Soal</div>
          </div>
          <div className="col col-9">
            <Input placeholder="" onChange={updateKodeSoal} />
          </div>
        </div>
        <div className="row k-mb-4 items-center">
          <div className="col col-3">
            <div className="k-label">Mata Pelajaran</div>
          </div>
          <div className="col col-9">
            <Input placeholder="" onChange={updateMataPelajaran} />
          </div>
        </div>

        {/* <div className="row k-mb-4 items-center">
          <div className="col col-3">
            <div className="k-label">Kode Kisi-kisi</div>
          </div>
          <div className="col col-9">
            <Input
              value={kodeKisiKisiValue}
              placeholder=""
              onChange={handleKodeKisiKisiChange}
            />
          </div>
        </div> 
      </div>
      */}
        {/*
      <hr className="full-width" />
      <div className="row k-mb-4 k-mt-10 items-center">
        <div className="col text-right ">
          <Button
            onClick={handleCekSoalClick}
            className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md px-5 mx-1"
          >
            Cari Soal
          </Button>
        </div>
      </div>
    */}
      </div>
    </div>
  );
};
