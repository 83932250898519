import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const soalFinderSlice = createApi({
  reducerPath: 'soalFinderApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_API_URL}/soal` }),
  tagTypes: ['soal_finder'],
  endpoints: (builder) => ({
    getPagingStatus: builder.query({
      query: ({ kodeSoal, mataPelajaran, indikator, paging = 100, token }) => ({
        url: `/search-get-pagination`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: { kodeSoal, mataPelajaran, indikator, paging }
      }),
      providesTags: ['soal_finder'],
    }),
    getListSoal: builder.query({
      query: ({ kodeSoal, mataPelajaran, indikator, paging = 100, page = 1, token, includeAlokasi }) => ({
        url: '/search-get-list',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: { kodeSoal, mataPelajaran, indikator, paging, page, includeAlokasi },
      }),
      invalidatesTags: ['soal_finder'],
    }),
    downloadSoals: builder.mutation({
      queryFn: async ({ kodeSoal, mataPelajaran, indikator, paging = 100, page = 1, token, includeAlokasi, fileName }, api, extraOptions, baseQuery) => {
        const result = await baseQuery({
          url: '/download-soals',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          method: 'POST',
          body: { kodeSoal, mataPelajaran, indikator, paging, page, includeAlokasi },
          cache: 'no-cache',
          responseHandler: ((response) => response.blob())
        })
        var hiddenElement = document.createElement('a');
        var url = window.URL || window.webkitURL;
        var blobData = url.createObjectURL(result.data);
        hiddenElement.href = blobData;
        hiddenElement.target = '_blank';
        hiddenElement.download = fileName;
        hiddenElement.click();
        return { data: null }
      }
    }),
    uploadEmptyMapelsFileBlob: builder.mutation({
      query: ({ fileName, serializedWord, token }) => ({
        url: '/empty-mapel-upload-file',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: { fileName, serializedWord },
      }),
      invalidatesTags: ['soal_finder'],
    }),
  }),
});

export const {
  useGetPagingStatusQuery,
  useGetListSoalQuery,
  useLazyGetListSoalQuery,
  useUploadEmptyMapelsFileBlobMutation,
  useDownloadSoalsMutation,
} = soalFinderSlice;
