import { useState, useEffect } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Input } from "@progress/kendo-react-inputs";
import { ExamFullDetail } from "../exam/ExamFullDetail";

export const Filter = ({
  handleStartFiltering,
  updateKodeSoal,
  updateMataPelajaran,
  updateIndikator,
}) => {
  const [visible, setVisible] = useState(false);
  const handleOpenSoalDetailDialog = (dataItem) => {
    setVisible(!visible);
  };

  const handleToggleDialog = () => {
    setVisible(!visible);
  };

  return (
    <div className="px-11 mt-6">
      <div className="subtitle">
        <h3>Filter Soal</h3>
      </div>
      <div>
        <div className="row k-mb-4 items-center">
          <div className="col col-3">
            <div className="k-label">Kode Soal</div>
          </div>
          <div className="col col-9">
            <Input placeholder="" onChange={updateKodeSoal} />
          </div>
        </div>

        <div className="row k-mb-4 items-center">
          <div className="col col-3">
            <div className="k-label">Mata Pelajaran</div>
          </div>
          <div className="col col-9">
            <Input placeholder="" onChange={updateMataPelajaran} />
          </div>
        </div>

        <div className="row k-mb-4 items-center">
          <div className="col col-3">
            <div className="k-label">Indikator</div>
          </div>
          <div className="col col-9">
            <Input placeholder="" onChange={updateIndikator} />
          </div>
        </div>
        {/* 
        <hr className="full-width" />

        <div className="row k-mb-4 k-mt-10 items-center">
          <div className="col text-right ">
            <Button
              onClick={handleOpenSoalDetailDialog}
              className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md px-5 mx-1"
            >
              Create Soal
            </Button>
          </div> 
          <div className="padding-left: 10px">
            <Button
              onClick={handleStartFiltering}
              className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md px-5 mx-1"
            >
              Cari Soal
            </Button>
          </div>
       
        </div>
         */}
      </div>

      {visible && (
        <ExamFullDetail
          kodeSoal={"SOAL_BARU"}
          toggleDialog={handleToggleDialog}
        />
      )}
    </div>
  );
};
