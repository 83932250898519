import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { importIcon, plusIcon } from "@progress/kendo-svg-icons";

export const Filter = ({ updateKeywords }) => {
  let navigate = useNavigate();
  return (
    <>
      <div className="row px-2">
        <Button
          svgIcon={plusIcon}
          onClick={() => navigate(`/home/create-user`)}
          className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md"
        >
          Tambah Pengguna
        </Button>
        <Button
          svgIcon={importIcon}
          onClick={() => navigate(`/home/import-user`)}
          className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md ml-8"
        >
          Import Pengguna
        </Button>
      </div>
      <hr className="my-3"></hr>

      <div>
        <div className="row k-mb-4 items-center">
          <div className="col col-2">
            <div className="k-label">Filter</div>
          </div>
          <div className="col col-10">
            <Input placeholder="" onChange={updateKeywords} />
          </div>
        </div>
      </div>
    </>
  );
};
