import { useState, useEffect, forwardRef, useCallback } from "react";
import { get, set } from "lodash";
import useAuth from "../../common/hooks/useAuth";
import { columns } from "../soal-revisi-kanban/settings";
import { useLazyGetUserQuery } from "../../features/userSlice";
import { Button } from "@progress/kendo-react-buttons";
import { SvgIcon } from "@progress/kendo-react-common";
import { arrowLeftIcon, arrowRightIcon } from "@progress/kendo-svg-icons";

export const AssignStatusButton = forwardRef((props) => {
  const { user } = useAuth();
  const [GetUserQuery] = useLazyGetUserQuery();
  const [actions, setActions] = useState([]);

  const createSelectActions = useCallback(async (usersAlloc, currentColumn) => {
    // get assigness data
    const assignees = [];
    for (const userAlloc of usersAlloc) {
      const userDto = await GetUserQuery({
        token: user.accessToken,
        id: userAlloc.UserId,
      });
      assignees.push(userDto.data.result.user);
    }

    const assignStatusMembers = [];
    for (const nextPhaseId of get(currentColumn, "[0].nextPhase", [])) {
      const nextPhaseColumn = columns.filter((x) => x.id === nextPhaseId.id);
      // const nextPhaseUser = assignees.filter(x=>.i)
      const assignStatusMember = {
        nextPhaseColumnId: nextPhaseId.id,
        nextPhaseLabel: nextPhaseId.label,
        nextPhaseType: nextPhaseId.type,
      };

      if (nextPhaseColumn.length > 0) {
        const userReponsibility = assignees.filter((assignee) =>
          get(nextPhaseColumn, "[0].allowedRoles", []).includes(
            get(assignee, "role.id", "X")
          )
        );

        if (userReponsibility.length > 0) {
          set(assignStatusMember, "user.uid", userReponsibility[0].uid);
          set(
            assignStatusMember,
            "user.photoURL",
            userReponsibility[0].photoURL
          );
          set(
            assignStatusMember,
            "user.displayName",
            userReponsibility[0].displayName
          );
        }
      }

      assignStatusMembers.push(assignStatusMember);
    }

    setActions(assignStatusMembers);
  }, []);

  useEffect(() => {
    // console.log("something changes", { props });
    const currentColumn = columns.filter(
      (x) => x.id === get(props, "soalAttr.Status", 0)
    );
    createSelectActions(get(props, "usersAlloc", []), currentColumn);
  }, [props.soalAttr]);

  const handleClick = async (evt, action) => {
    props.assignStatusClick({ evt, data: action });
  };

  // console.log("assign Status state", { actions });

  const renderUsers = () => {
    return actions.map((action) => {
      // console.log("actionsnya", { action });
      return (
        <div className="btn-action" key={action.nextPhaseColumnId}>
          <Button
            startIcon={
              action.nextPhaseType === "back" ? (
                <SvgIcon icon={arrowLeftIcon} />
              ) : (
                <></>
              )
            }
            endIcon={
              action.nextPhaseType === "next" ? (
                <SvgIcon icon={arrowRightIcon} />
              ) : (
                <></>
              )
            }
            className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md"
            onClick={(e) => handleClick(e, action)}
          >
            {action.nextPhaseLabel}
          </Button>
        </div>
      );
    });
  };

  return <div className="btn-actions">{renderUsers()}</div>;
});
