import { useState, useEffect } from "react";
import { get } from "lodash";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { skipToken } from "@reduxjs/toolkit/query";
import useAuth from "../../common/hooks/useAuth";
import { useGetAllUsersQuery } from "../../features/userSlice";
import {
  NumberCell,
  ActionCell,
  StatusCell,
} from "../custom-control/custom-cells";
import TheSpinner from "../generic/TheSpinner";

export const Listing = ({ startFiltering, filterParam }) => {
  const { user } = useAuth();
  const [curentPageValue, setCurrentPageValue] = useState(1);
  const { data, isLoading, isSuccess, isError, error } = useGetAllUsersQuery(
    user.accessToken
      ? {
          currPage: curentPageValue,
          token: user.accessToken,
          keywords: filterParam.keywords,
        }
      : skipToken
  );

  /*
  console.log("List User result", {
    // data,
    isLoading,
    isSuccess,
    isError,
    error,
  });
  */

  const pageChange = (event) => {
    const currPage = event.page.skip / get(data, "result.pageSize", 0) + 1;
    setCurrentPageValue(currPage);
    // console.log("page changes", { event: event.page, currPage });
  };

  const renderContent = () => {
    return (
      <div>
        <Grid
          data={get(data, "result.users", [])}
          className="h-auto"
          total={get(data, "result.totalRecord", 0)}
          take={get(data, "result.pageSize", 0)}
          skip={(curentPageValue - 1) * get(data, "result.pageSize", 0)}
          pageable={{
            buttonCount: 10,
          }}
          onPageChange={pageChange}
        >
          <GridColumn cells={{ data: NumberCell }} title="No." width={50} />
          <GridColumn field="displayName" title="Name Lengkap" />
          <GridColumn field="role.roleName" title="Role" />
          <GridColumn field="phoneNumber" title="Nomor Telepon" />
          <GridColumn field="email" title="Email" />
          <GridColumn cells={{ data: StatusCell }} title="Enabled" width={80} />
          <GridColumn title="Action" cells={{ data: ActionCell }} width={80} />
        </Grid>
      </div>
    );
  };

  if ((isLoading || !isSuccess || !data || !data.result) && !isError) {
    return <TheSpinner />;
  } else if (isSuccess && data && data.result) {
    return renderContent();
  } else if (isError) {
    return <span>{error.message}</span>;
  }
};
