import { useState, useEffect, useRef } from "react";
import { get } from "lodash";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import useAuth from "../../common/hooks/useAuth";
import { skipToken } from "@reduxjs/toolkit/query";
import { Stepper } from "@progress/kendo-react-layout";
import {
  cartIcon,
  dollarIcon,
  eyeIcon,
  mapMarkerIcon,
  trackChangesAcceptIcon,
} from "@progress/kendo-svg-icons";
import { useGetSoalAttributeQuery } from "../../features/soalEditorSlice";
import { DEFAULT_SOAL_BARU_NAME } from "../exam/ExamFullDetail";
import { columns } from "../soal-revisi-kanban/settings";

export const SoalStep = () => {
  let { id } = useParams();
  const { user } = useAuth();

  /*
  const items = [
    {
      label: "Success Step",
      svgIcon: cartIcon,
    },
    {
      label: "Error Step",
      svgIcon: mapMarkerIcon,
    },
    {
      label: "Disabled Step",
      svgIcon: dollarIcon,
    },
    {
      label: "Optional Step",
      svgIcon: eyeIcon,
    },
  ];
  */

  const {
    data: soalAttribute,
    isLoading: getSoalAttributeLoading,
    isSuccess: getSoalAttributeSuccess,
  } = useGetSoalAttributeQuery(
    id && id !== DEFAULT_SOAL_BARU_NAME
      ? { kodeSoal: id, token: user.accessToken }
      : skipToken
  );

  const items = [];
  for (const column of columns){
    items.push({
      label: column.title,
    })

  }

  // console.log("Soal Steps", { soalAttribute, columns, items });

  return (
    <>
      {id !== DEFAULT_SOAL_BARU_NAME && (
        <div className="stepper">
          <Stepper value={get(soalAttribute, "result.Status")} items={items} />
        </div>
      )}
    </>
  );
};
