export const columns = [
  {
    id: 0,
    title: "Penulis",
    status: "draft",
    needAssignee: true,
    allowedRoles: [
      "5",
    ],
    nextPhase: [
      { id: 1, label: "lanjutkan untuk direvisi", type: 'next' }
    ],
  },
  {
    id: 1,
    title: "Penelaah",
    status: "revisi",
    needAssignee: true,
    allowedRoles: [
      "2",
    ],
    nextPhase: [
      { id: 0, label: "Kembalikan kepada penulis", type: 'back' },
      { id: 2, label: "lanjutkan untuk QC", type: 'next' }
    ],
  },
  {
    id: 2,
    title: "QC",
    status: "qc",
    needAssignee: true,
    allowedRoles: [
      "4",
    ],
    nextPhase: [
      { id: 1, label: "Kembalikan kepada revisi", type: 'back' },
      { id: 3, label: "lanjutkan untuk Publish", type: 'next' }
    ],
  },
  {
    id: 3,
    title: "Published",
    status: "published",
    needAssignee: false,
    allowedRoles: [
      "4",
    ],
    nextPhase: [],
  },
];

export const types = [
  {
    id: "0",
    priority: "UNKNOWN",
    color: "#ffffff",
  },
  {
    id: "1",
    priority: "Pilihan Ganda",
    color: "#b5e61d",
  },
  {
    id: "2",
    priority: "Pilihan Ganda Kompleks",
    color: "#8bb310",
  },
  {
    id: "3",
    priority: "Prosedur Tertutup",
    color: "#1710b3",
  },
  {
    id: "4",
    priority: "Essay",
    color: "#eb0f35",
  },
  {
    id: "5",
    priority: "Menjodohkan",
    color: "#eef24c",
  },
  {
    id: "6",
    priority: "Highlight",
    color: "#068209",
  },
  {
    id: "7",
    priority: "Highlights",
    color: "#068209",
  },
  {
    id: "8",
    priority: "Jawaban tertutup",
    color: "#466047",
  },
  {
    id: "9",
    priority: "PG & Essay",
    color: "#39a48f",
  },
  {
    id: "10",
    priority: "PGK & Essay",
    color: "#5c2777",
  },
  {
    id: "11",
    priority: "Jt & Essay",
    color: "#b57ad3",
  },
  {
    id: "12",
    priority: "Highlight Select",
    color: "#796d80",
  },
  {
    id: "13",
    priority: "Menjodohkan Garis",
    color: "#602b3d",
  },
  {
    id: "14",
    priority: "Menjodohkan Garis Fleksible",
    color: "#5f162f",
  }
];

export const cards = [
  {
    id: 1,
    title: "Campaigns",
    order: 0,
    description: "Create a new landing page for campaign",
    status: "draft",
    priority: {
      priority: "Pilihan Ganda",
      color: "#b5e61d",
    },
    image:
      "https://demos.telerik.com/kendo-ui/content/web/taskboard/taskboard-demo-illustrations-01.png",
  },
  {
    id: 2,
    title: "Newsletters",
    order: 2,
    description: "Send newsletter",
    status: "draft",
    priority: {
      priority: "Pilihan Ganda",
      color: "#b5e61d",
    },
    image:
      "https://demos.telerik.com/kendo-ui/content/web/taskboard/taskboard-demo-illustrations-02.png",
  },
  {
    id: 3,
    title: "Ads Analytics",
    order: 1,
    description: "Review ads performance",
    status: "draft",
    priority: {
      priority: "Pilihan Ganda",
      color: "#b5e61d",
    },
    image:
      "https://demos.telerik.com/kendo-ui/content/web/taskboard/taskboard-demo-illustrations-03.png",
  },
  {
    id: 4,
    title: "SEO Analytics",
    order: 10,
    description: "Review SEO results",
    status: "qc",
    priority: {
      priority: "Pilihan Ganda",
      color: "#b5e61d",
    },
    image:
      "https://demos.telerik.com/kendo-ui/content/web/taskboard/taskboard-demo-illustrations-04.png",
  }
];

export const TASKBOARD_ITEM_TYPE = 'data-taskboard-type';
export const TASKBOARD_ITEM_ID = 'data-taskboard-id';
export const TASKBOARD_PLACEHOLDER = 'data-taskboard-placeholder';
export const TASKBOARD_COLUMN = 'column';
export const TASKBOARD_TASK = 'task';