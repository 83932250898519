import { useState, useEffect, useCallback, useRef } from "react";
import { get } from "lodash";
import { NameInitialsAvatar } from "react-name-initials-avatar";
import useAuth from "../../common/hooks/useAuth";
import { useLazyGetUserQuery } from "../../features/userSlice";
import { CardBody } from "@progress/kendo-react-layout";
import { columns } from "./settings";
import BaseAvatar from "../../assets/wd.png";

export const TaskBoardCardBody = (props) => {
  const { user } = useAuth();
  const [GetUserQuery] = useLazyGetUserQuery();
  const [userList, setUserList] = useState([]);

  const fetchAllocationUserData = useCallback(async (task) => {
    const userAllocs = [];
    const currentCols = columns.filter((x) => x.status === task.status);
    if (currentCols.length > 0) {
      for (const userAlloc of task.usersAllocation) {
        const userDetail = await GetUserQuery({
          token: user.accessToken,
          id: userAlloc.UserId,
        });

        // console.log("userDetail", { task, userDetail });
        const isUserRoleValid = currentCols[0].allowedRoles.filter(
          (x) => x === get(userDetail, "data.result.user.role.id")
        );

        userAllocs.push({
          id: userAlloc.UserId,
          photoURL: get(userDetail, "data.result.user.photoURL")
            ? get(userDetail, "data.result.user.photoURL")
            : "",
          displayName: get(userDetail, "data.result.user.displayName"),
          roleId: get(userDetail, "data.result.user.role"),
          isRoleValid: isUserRoleValid.length > 0,
        });
      }

      // console.log("userAlloc", { userAllocs });
      setUserList(userAllocs);
    }
  }, []);

  useEffect(() => {
    if (
      props.task &&
      props.task.usersAllocation &&
      props.task.usersAllocation.length > 0
    ) {
      // console.log("sampe nih useEffect", { task: props.task });
      fetchAllocationUserData(props.task);
    }
  }, [props]);

  const renderUsers = () => {
    if (userList.length > 0) {
      // console.log("usersAllocation", { usersAllocation });
      return userList.map((currUser) => {
        // console.log("currUser", { currUser });
        if (currUser.photoURL !== "") {
          return (
            <div
              key={currUser.id}
              data={currUser.displayName}
              className={currUser.isRoleValid ? "user" : "user cross"}
              title={currUser.displayName}
              style={{
                backgroundImage: `url(${currUser.photoURL})`,
              }}
            ></div>
          );
        } else {
          return (
            <div
              key={currUser.id}
              className={
                currUser.isRoleValid
                  ? "user user-profile"
                  : "user user-profile cross"
              }
              title={currUser.displayName}
            >
              <NameInitialsAvatar
                name={currUser.displayName}
                size="100%"
                bgColor="black"
                textColor="white"
              />
            </div>
          );
        }
      });
    }

    return <></>;
  };

  const beautifyDescription = (text) => {
    if (text && text.length > 0 && text.includes(" ")) {
      const textSplit = text.split(" ");
      textSplit.shift();
      const sanitizeText = textSplit.join(" ");

      const result =
        sanitizeText.length > 80
          ? sanitizeText.slice(0, 77) + "..."
          : sanitizeText;

      // console.log("check", { textSplit, result });
      return result;
    } else {
      return text;
    }
  };

  // console.log("userList", { userList });

  return (
    <CardBody>
      <div>{beautifyDescription(props.task.description)}</div>
      <div className="user-list">{renderUsers()}</div>
    </CardBody>
  );
};
