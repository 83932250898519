import { cloneElement, useState, useRef, useEffect } from "react";
import { intersectionWith } from "lodash";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { DropdownOptionPhoto } from "../custom-control/custom-options";
import { filterBy } from "@progress/kendo-data-query";

const delay = 500;

export const MultiSelectDropdownCell = (props) => {
  const timeout = useRef();
  const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;
  const [items, setItems] = useState([]);
  const isInEdit = field === dataItem.inEdit;
  const selectedValue =
    field && dataItem[`selected${field}`] ? dataItem[`selected${field}`] : [];

  useEffect(() => {
    setItems(dataItem.users);
  }, [dataItem]);

  const onChange = (e) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value,
      });
    }
  };

  const itemRender = (li, itemProps) => {
    const index = itemProps.index;
    const itemChildren = (
      <div className="row" key={index}>
        <div className="col col-3">
          <span
            style={{
              color: "#00F",
            }}
          >
            <DropdownOptionPhoto dataItem={itemProps.dataItem} />
          </span>
        </div>
        <div className="col col-9">{itemProps.dataItem.displayName}</div>
      </div>
    );
    return cloneElement(li, li.props, itemChildren);
  };

  const tagRender = (tagData, li) =>
    cloneElement(li, li.props, [
      <span className="custom-tag" key={tagData.data[0].uid}>
        <DropdownOptionPhoto dataItem={tagData.data[0]} />
      </span>,
      li.props.children,
    ]);

  const filterChange = (event) => {
    clearTimeout(timeout.current);

    timeout.current = setTimeout(() => {
      const filtered = filterBy(dataItem.users.slice(), event.filter);
      setItems(filtered);
    }, delay);

    setItems(dataItem.users);
  };

  const defaultRendering = (
    <td
      style={{
        textAlign: "center",
      }}
      aria-colindex={ariaColumnIndex}
      data-grid-col-index={columnIndex}
    >
      {isInEdit ? (
        <div>
          <MultiSelect
            data={items}
            itemRender={itemRender}
            tagRender={tagRender}
            placeholder="Users"
            allowCustom={true}
            textField="displayName"
            dataItemKey="uid"
            onChange={onChange}
            defaultValue={intersectionWith(
              dataItem.users,
              selectedValue ?? [],
              (o, n) => o.uid === n
            )}
            filterable={true}
            onFilterChange={filterChange}
          />
        </div>
      ) : selectedValue ? (
        <div>
          {intersectionWith(
            dataItem.users,
            selectedValue ?? [],
            (o, n) => o.uid === n
          ).map((item) => {
            return (
              <span className="custom-tag">
                <div className="row">
                  <div className="col col-1">
                    <span
                      style={{
                        color: "#00F",
                      }}
                    >
                      <DropdownOptionPhoto dataItem={item} />
                    </span>
                  </div>
                  <div className="col col-9">
                    <p className="text-start">{item.displayName}</p>
                  </div>
                </div>
              </span>
            );
          })}
        </div>
      ) : (
        "-"
      )}
    </td>
  );

  return render?.call(undefined, defaultRendering, props);
};
