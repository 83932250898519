import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { ExamFullDetail } from "../components/exam/ExamFullDetail";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
export const ExamDetail = () => {
  const isExpand = useSelector((state) => state.globalState.drawerExpand);
  const [visible, setVisible] = React.useState(false);
  const handleToggleDialog = () => {
    setVisible(!visible);
  };

  return (
    <div
      className={
        isExpand
          ? "grid-layout-container mt-2 expand"
          : "grid-layout-container mt-2"
      }
    >
      <GridLayout
        gap={{
          rows: 1,
          cols: 1,
        }}
        rows={[
          {
            height: 750,
          },
        ]}
      >
        <GridLayoutItem row={1} col={1} className="grid-item">
          <Button onClick={handleToggleDialog}>open</Button>

          {visible && (
            <ExamFullDetail
              kodeSoal="TES-SOAL-001"
              toggleDialog={handleToggleDialog}
            />
          )}
          {/*
          <ExamFullDetail kodeSoal="TES-SOAL-001" />
          */}
        </GridLayoutItem>
      </GridLayout>
    </div>
  );
};
