import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const transferSoalSlice = createApi({
  reducerPath: 'transferSoalApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_API_URL}/transfer-mahir` }),
  tagTypes: ['transfer_soal'],
  endpoints: (builder) => ({
    getPagingStatus: builder.query({
      query: ({ mataPelajaran, kodeSoal, token }) => ({
        url: '/get-paging-status',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: { mataPelajaran, kodeSoal },
      }),
      providesTags: ['transfer_soal'],
    }),
    getListSoal: builder.query({
      query: ({ mataPelajaran, kodeSoal, page, token }) => ({
        url: `/get-list-soal`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: { mataPelajaran, kodeSoal, page },
      }),
      providesTags: ['transfer_soal'],
    }),
    transferSoal: builder.mutation({
      query: ({ mataPelajaran, kodeSoal, token }) => ({
        url: `/transfer-soal`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: { mataPelajaran, kodeSoal },
      }),
      invalidatesTags: ['transfer_soal'],
    }),
    transferSoalToMahir: builder.mutation({
      query: ({ mataPelajaran, kodeSoal, totalPages, email, token }) => ({
        url: `/transfer-soal-to-mahir`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: { mataPelajaran, kodeSoal, totalPages, email },
      }),
      invalidatesTags: ['transfer_soal_to_mahir'],
    }),
  }),
});

export const {
  useGetPagingStatusQuery,
  useGetListSoalQuery,
  useTransferSoalMutation,
  useTransferSoalToMahirMutation,
} = transferSoalSlice;
