import { useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { CardHeader, Menu } from "@progress/kendo-react-layout";
import { Popup } from "@progress/kendo-react-popup";
export const TaskBoardCardHeader = (props) => {
  const buttonRef = useRef();
  // console.log("TaskBoardCardHeader props", { props });

  return (
    <CardHeader className="k-hbox">
      <span className={"k-card-title"} onClick={props.onShowPreviewPane}>
        {props.title}
      </span>
      <span className={"k-spacer"} />

      {/*
      <div className="k-card-header-actions">
        <Button
          fillMode="flat"
          icon="more-vertical"
          ref={buttonRef}
          onClick={props.showMenu ? props.onHideMenu : props.onShowMenu}
          onBlur={props.onMenuButtonBlur}
        />
        <Popup
          anchor={buttonRef.current && buttonRef.current.element}
          show={props.showMenu}
          ref={props.popupRef}
        >
          <Menu
            vertical={true}
            onSelect={props.onMenuItemSelect}
            items={props.menuItems.filter((x) => x.text !== "Delete card")}
            className={"k-context-menu"}
          />
        </Popup>
      </div>
      */}
    </CardHeader>
  );
};
