import { useState } from "react";
import { useDispatch } from "react-redux";
import useAuth from "../../common/hooks/useAuth";
import BlockUI from "../generic/BlockUI";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import {
  usePreviewBatchFileBlobMutation,
} from "../../features/alokasiRevisiSlice";
import { showNotification } from "../../features/globalStateSlice";
import { 
  useDownloadTemplateAlokasiRevisiMutation, 
  useCreateAlokasiSoalUsersMutation 
} from "../../features/alokasiRevisiSlice";
import { NOTIFICATION_STYLE } from "../generic/wd-notification";
import { BatchUploadListing } from "./BatchUploadListing";

export const BatchUpload = () => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const [previewBatchFileBlob, { isLoading: isLoadingPreviewBatchFileBlob, data: batchSoals }] =
    usePreviewBatchFileBlobMutation();
  const [downloadTemplateAlokasiRevisi] = useDownloadTemplateAlokasiRevisiMutation();
  const [
    createAlokasiSoalUsers,
    { isFetching: createAlokasiSoalUserLoading },
  ] = useCreateAlokasiSoalUsersMutation();
  const [base64Image, setBase64Image] = useState("");
  const [fileName, setFileName] = useState("");

  const [pageProgress, setPageProgress] = useState(false);

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const handleFileInputChange = async (e) => {
    const file = e.target.files[0];
    // console.log("handleFileInputChange", { file });

    if (file && file.size > 5242835) {
      alert("file terlalu besar!");
      return;
    }

    getBase64(file)
      .then(async (result) => {
        // console.log("get base64", result);
        setBase64Image(result);
        setFileName(file.name);

        await previewBatchFileBlob({
          fileName: file.name,
          serializedWord: result ? result.split(",")[1] : undefined,
          token: user.accessToken,
        });

        dispatch(
          showNotification({
            style: NOTIFICATION_STYLE.SUCCESS,
            message: `Batch upload alokasi soal file telah disimpan`,
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUploadClick = async (updatedAlokasiSoals) => {
    setPageProgress(true);
    await createAlokasiSoalUsers({ alokasiSoal: updatedAlokasiSoals, token: user.accessToken });
    setPageProgress(false);

    dispatch(
      showNotification({
        style: NOTIFICATION_STYLE.SUCCESS,
        message: `Alokasi revisi soal telah disimpan`,
      })
    );
  };

  const handleDownloadTemplate = async () => {
    setPageProgress(true);
    await downloadTemplateAlokasiRevisi({ 
      fileName: 'TemplateBatchUploadAlokasiRevisi.xlsx', 
      token: user.accessToken 
    });
    setPageProgress(false);
  };
  
  return (
    <div className="grid-layout-container mt-2">
      <BlockUI blocking={(
        isLoadingPreviewBatchFileBlob || 
        createAlokasiSoalUserLoading ||
        pageProgress
      )} />
      <GridLayout
        gap={{
          rows: 2,
          cols: 1,
        }}
        rows={[
          {
            height: 300,
          },
          {
            height: 750,
          },
        ]}
      >
        <GridLayoutItem row={1} col={1} className="grid-item">
          <div className="px-11 mt-6">
            <div className="subtitle">
              <h3>Upload Alokasi Soal</h3>
            </div>
            <div>
              <div className="row k-mb-4 items-center">
                <div className="col col-4">
                  <div className="k-label">Pilih file</div>
                </div>
                <div className="col col-8">
                <input
                    className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md"
                    type="file"
                    name="file"
                    onChange={handleFileInputChange}
                    accept=".xls,.xlsx"
                  />
                </div>
              </div>

              <hr className="full-width" />

              <div className="row k-mb-4 k-mt-10 items-center">
                <div className="col text-right ">
                  <Button
                    onClick={handleDownloadTemplate}
                    className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md px-5 mx-1"
                  >
                    Download Template
                  </Button>
                </div>
              </div>
              
            </div>
          </div>
        </GridLayoutItem>
        <GridLayoutItem row={2} col={1} className="grid-item clear-bg topbrr">
          <BatchUploadListing 
            batchSoals={batchSoals} 
            handleUploadClick={handleUploadClick} />
        </GridLayoutItem>
      </GridLayout>
    </div>
  );
};
