import { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Input, MaskedTextBox, Checkbox } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import { saveIcon, cancelIcon } from "@progress/kendo-svg-icons";
import { skipToken } from "@reduxjs/toolkit/query";
import { get, maxBy } from "lodash";
import { isNullOrWhiteSpace } from "../../common/generic/GenericFunc";
// import UserRole from "../../data/user-role";
import useAuth from "../../common/hooks/useAuth";
import {
  useCreateUserMutation,
  useGetUserQuery,
  useUpdateUserMutation,
  useGetRolesQuery,
} from "../../features/userSlice";
import { showNotification } from "../../features/globalStateSlice";
import { NOTIFICATION_STYLE } from "../generic/wd-notification";

export const Detail = () => {
  const DEFAULT_ID_TYPE = "KTP";
  const [defaultRoleValue, setDefaultRoleValue] = useState({
    roleName: "Unknown",
    id: "X",
    level: 1000,
  });
  const { user } = useAuth();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [createUser, { isLoading: isCreating }] = useCreateUserMutation();
  const [updateUser, { isLoading: isUpdating }] = useUpdateUserMutation();
  let { id } = useParams();
  const userLevel = useSelector((state) => state.globalState.userLevel);

  const [currUserPhotoUrl, setCurrUserPhotoUrl] = useState("");

  const {
    data: userDetail,
    isLoading: getUserLoading,
    isSuccess: getUserSuccess,
  } = useGetUserQuery(
    !isNullOrWhiteSpace(id) ? { token: user.accessToken, id } : skipToken
  );

  const {
    data: rolesDetail,
    isLoading: getRolesLoading,
    isSuccess: getRolesSuccess,
  } = useGetRolesQuery({ token: user.accessToken });

  const [identityTypeValue, setIdentityTypeValue] = useState(DEFAULT_ID_TYPE);
  const handleIdentityTypeChange = (event) => {
    setIdentityTypeValue(event.target.value);
  };

  const [nircValue, setNircValue] = useState("");
  const handleNircChange = useCallback((event) => {
    setNircValue(event.value);
  }, []);

  const [fullNameValue, setFullNameValue] = useState("");
  const handlefullNameChange = useCallback((event) => {
    setFullNameValue(event.value);
  }, []);

  const [emailValue, setEmailValue] = useState("");
  const handleEmailChange = useCallback((event) => {
    setEmailValue(event.value);
  }, []);

  const [passwordValue, setPasswordValue] = useState("");
  const handlePasswordChange = useCallback((event) => {
    setPasswordValue(event.value);
  }, []);

  const [phoneValue, setPhoneValue] = useState("");
  const handlePhoneChange = useCallback((event) => {
    setPhoneValue(event.value);
  }, []);

  const [roleValue, setRoleValue] = useState({
    value: defaultRoleValue,
  });
  const handleRoleChange = (event) => {
    setRoleValue({
      value: event.target.value,
    });
  };

  const [disabledValue, setDisabledValue] = useState(false);
  const handleDisabledValue = useCallback((event) => {
    console.log("disabled value", { value: !event.value });
    setDisabledValue(!event.value);
  }, []);

  const [base64Image, setBase64Image] = useState("");
  const [imageExtension, setImageExtension] = useState("");

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    // console.log("handleFileInputChange", { file });

    if (file.size > 5242835) {
      alert("file terlalu besar!");
      return;
    }

    getBase64(file)
      .then((result) => {
        // console.log("get base64", result);
        setBase64Image(result);
        setImageExtension(file.name.split(".").pop());
      })
      .catch((err) => {
        console.log("getBase64error", err);
      });
  };

  useEffect(() => {
    if (userDetail && !getUserLoading && getUserSuccess) {
      console.log("CurrUser", { userDetail, rolesDetail });
      setFullNameValue(get(userDetail, "result.user.displayName", ""));
      setPhoneValue(get(userDetail, "result.user.phoneNumber", ""));
      setEmailValue(get(userDetail, "result.user.email", ""));
      setPasswordValue("");
      setDisabledValue(get(userDetail, "result.user.disabled", false));
      setCurrUserPhotoUrl(get(userDetail, "result.user.photoURL", ""));
      setIdentityTypeValue(
        get(userDetail, "result.user.meta.identifier.type", DEFAULT_ID_TYPE)
      );
      setNircValue(get(userDetail, "result.user.meta.identifier.number"));
      setRoleValue({
        value: get(userDetail, "result.user.role", defaultRoleValue),
      });
    }
  }, [userDetail]);

  useEffect(() => {
    if (rolesDetail && rolesDetail.result && rolesDetail.result.results) {
      const maxLevelRole = maxBy(rolesDetail.result.results, function (o) {
        return o.level;
      });

      if (isNullOrWhiteSpace(id) && isNullOrWhiteSpace(emailValue)) {
        setDefaultRoleValue(maxLevelRole);
        setRoleValue({ value: maxLevelRole });
      }
    }
  }, [rolesDetail]);

  const handleSaveClick = async () => {
    /* console.log("SaveNewUser", {
      identityTypeValue,
      nircValue,
      fullNameValue,
      emailValue,
      roleValue,
      base64Image: base64Image.split(",")[0],
      imageExtension,
      passwordValue,
      phoneValue,
    }); */

    let meta = undefined;
    if (!isNullOrWhiteSpace(nircValue)) {
      meta = {
        identifier: {
          type: identityTypeValue,
          number: nircValue,
        },
      };
    }

    const returned = await createUser({
      email: emailValue,
      phoneNumber: phoneValue,
      password: passwordValue,
      displayName: fullNameValue,
      photoExtension: base64Image ? imageExtension : undefined,
      photoChunk: base64Image ? base64Image.split(",")[1] : undefined,
      role: roleValue.value,
      disabled: disabledValue,
      meta,
      token: user.accessToken,
    });
    // console.log("returned", { returned });

    if (get(returned, "data.result.user.status", "") === "failed") {
      dispatch(
        showNotification({
          style: NOTIFICATION_STYLE.ERROR,
          message: `Failed to create new User ${get(
            returned,
            "data.result.user.errMessage",
            ""
          )}`,
        })
      );
    } else {
      dispatch(
        showNotification({
          style: NOTIFICATION_STYLE.SUCCESS,
          message: `User ${fullNameValue} already been created`,
        })
      );
      navigate(`/home/search-user`);
    }
  };

  const handleUpdateClick = async () => {
    /* console.log("UpdateUser", {
      id,
      identityTypeValue,
      nircValue,
      fullNameValue,
      emailValue,
      roleValue,
      base64Image: base64Image.split(",")[0],
      imageExtension,
      passwordValue,
      phoneValue,
    }); */

    let meta = undefined;
    if (!isNullOrWhiteSpace(nircValue)) {
      meta = {
        identifier: {
          type: identityTypeValue,
          number: nircValue,
        },
      };
    }

    const returned = await updateUser({
      id,
      email: isNullOrWhiteSpace(emailValue) ? undefined : emailValue,
      phoneNumber: isNullOrWhiteSpace(phoneValue) ? undefined : phoneValue,
      password: isNullOrWhiteSpace(passwordValue) ? undefined : passwordValue,
      displayName: isNullOrWhiteSpace(fullNameValue)
        ? undefined
        : fullNameValue,
      photoExtension: isNullOrWhiteSpace(base64Image)
        ? undefined
        : imageExtension,
      photoChunk: isNullOrWhiteSpace(base64Image)
        ? undefined
        : base64Image.split(",")[1],
      token: user.accessToken,
      disabled: disabledValue,
      role: roleValue.value,
      meta,
    });

    console.log("returned", { returned });

    if (get(returned, "data.result.user.status", "") === "failed") {
      dispatch(
        showNotification({
          style: NOTIFICATION_STYLE.ERROR,
          message: `Failed to Update ${get(
            returned,
            "data.result.user.errMessage",
            ""
          )}`,
        })
      );
    } else {
      dispatch(
        showNotification({
          style: NOTIFICATION_STYLE.SUCCESS,
          message: `User ${fullNameValue} already been Update`,
        })
      );
      navigate(`/home/search-user`);
    }
  };

  // console.log("Check User Createing", { isCreating, isUpdating });

  if (userLevel > get(userDetail, "result.user.role.level", 1000)) {
    return (
      <div className="px-11 mt-12">
        <span>Maaf User level anda tidak memungkinan melihat User Ini</span>
      </div>
    );
  } else {
    return (
      <div className="px-11 mt-12">
        <div>
          <div className="row k-mb-4 items-center">
            <div className="col col-2">
              <div className="k-label">Nomor ID (NIK/NIP/NRP/lainnya)</div>
            </div>
            <div className="col col-8">
              <DropDownList
                data={["KTP", "SIM", "PASSPORT"]}
                defaultValue={DEFAULT_ID_TYPE}
                value={identityTypeValue}
                onChange={handleIdentityTypeChange}
              />
            </div>
          </div>

          <div className="row k-mb-4 items-center">
            <div className="col col-2">
              <div className="k-label">Nomor Identitas</div>
            </div>
            <div className="col col-8">
              <Input
                value={nircValue}
                onChange={handleNircChange}
                placeholder="Nomer Identitas anda"
              />
            </div>
          </div>

          <div className="row k-mb-4 items-center">
            <div className="col col-2">
              <div className="k-label">Nama Lengkap</div>
            </div>
            <div className="col col-8">
              <Input
                value={fullNameValue}
                onChange={handlefullNameChange}
                placeholder="Nama Lengkap Anda"
                minLength={5}
              />
            </div>
          </div>

          <div className="row k-mb-4 items-center">
            <div className="col col-2">
              <div className="k-label">Nomor Telepon</div>
            </div>
            <div className="col col-8">
              <MaskedTextBox
                mask="+6200000000000"
                defaultValue="Format: +6211111111111"
                value={phoneValue}
                onChange={handlePhoneChange}
              />
            </div>
          </div>

          <div className="row k-mb-4 items-center">
            <div className="col col-2">
              <div className="k-label">Alamat Email</div>
            </div>
            <div className="col col-8">
              <Input
                value={emailValue}
                onChange={handleEmailChange}
                type="email"
                placeholder="Email Anda"
                disabled={id !== undefined}
              />
            </div>
          </div>

          <div className="row k-mb-4 items-center">
            <div className="col col-2">
              <div className="k-label">User Password</div>
            </div>
            <div className="col col-8">
              <Input
                value={passwordValue}
                onChange={handlePasswordChange}
                placeholder="****"
                type="password"
                minLength={5}
              />
            </div>
          </div>

          <div className="row k-mb-4 items-center">
            <div className="col col-2">
              <div className="k-label">Role</div>
            </div>
            <div className="col col-8">
              {rolesDetail && !getRolesLoading && getRolesSuccess && (
                <DropDownList
                  data={get(rolesDetail, "result.results", []).filter(
                    (x) => x.level > userLevel
                  )}
                  textField="roleName"
                  dataItemKey="id"
                  value={roleValue.value}
                  onChange={handleRoleChange}
                />
              )}
            </div>
          </div>

          <div className="row k-mb-4 items-center">
            <div className="col col-2">
              <div className="k-label">Enabled</div>
            </div>
            <div className="col col-8">
              <Checkbox
                defaultChecked={true}
                size={"large"}
                rounded={"large"}
                value={!disabledValue}
                onChange={handleDisabledValue}
              />
            </div>
          </div>

          <div className="row k-mb-4 items-center">
            <div className="col col-2">
              <div className="k-label">Foto Profil</div>
            </div>
            <div className="col col-8 ">
              <div className="row items-center">
                <div className="col col-6">
                  <input
                    className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md"
                    type="file"
                    name="file"
                    onChange={handleFileInputChange}
                    accept=".gif,.jpg,.jpeg,.png"
                  />
                </div>
                <div className="col col-4">
                  {base64Image !== "" && (
                    <img
                      src={base64Image}
                      className="max-h-48"
                      alt="userImage"
                    />
                  )}

                  {base64Image === "" && currUserPhotoUrl !== "" && (
                    <img
                      src={currUserPhotoUrl}
                      className="max-h-32 rounded-md"
                      alt="userImage"
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="col col-4 ml-6"></div>
          </div>

          <div className="row k-mb-4 k-mt-10 items-center">
            <div className="col col-2"></div>
            <div className="col col-8 text-right ">
              {isNullOrWhiteSpace(id) && (
                <Button
                  svgIcon={saveIcon}
                  onClick={handleSaveClick}
                  className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md px-5 mx-1"
                >
                  Save
                </Button>
              )}
              {!isNullOrWhiteSpace(id) && (
                <Button
                  svgIcon={saveIcon}
                  onClick={handleUpdateClick}
                  className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md px-5 mx-1"
                >
                  Update
                </Button>
              )}
              <Button
                svgIcon={cancelIcon}
                onClick={() => navigate(`/home/search-user`)}
                className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md px-5 mx-1"
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
