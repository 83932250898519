import { useState, useEffect, useCallback, useMemo } from "react";
import { get } from "lodash";
import { skipToken } from "@reduxjs/toolkit/query";
import useAuth from "../../common/hooks/useAuth";
import BlockUI from "../generic/BlockUI";
import { TaskBoard, TaskBoardToolbar } from "@progress/kendo-react-taskboard";
import { Input } from "@progress/kendo-react-inputs";
import { filterBy } from "@progress/kendo-data-query";
import { Card } from "./card";
import { columns, types } from "./settings";
import { Column } from './column';
import {
  useGetPagingStatusQuery,
  useLazyGetListSoalQuery,
} from "../../features/soalFinderSlice";

export const Boards = () => {
  const { user } = useAuth();
  const PAGE_SIZE = 50;
  // const [curentPageValue, setCurrentPageValue] = useState(1);
  const [filter, setFilter] = useState("");
  const [taskData, setTaskData] = useState([]);
  const [columnsData, setColumnsData] = useState(columns);
  const [allSoalData, setAllSoalData] = useState([]);
  const [GetListSoalQuery] = useLazyGetListSoalQuery();

  const {
    data: getPagingStatusData,
    // isFetching: getPagingStatusLoading,
    // isSuccess: isGetPagingStatusSuccess,
    // isError: isGetPagingStatusError,
    // error: getPagingStatusError,
  } = useGetPagingStatusQuery(
    user.accessToken
      ? {
          kodeSoal: "",
          mataPelajaran: "",
          indikator: "",
          paging: PAGE_SIZE,
          token: user.accessToken,
          userId: user.uid,
        }
      : skipToken
  );

  const fetchAllData = useCallback(async (pagingDataResult) => {
    const allSoal = [];
    for (var loop = 0; loop < pagingDataResult.TotalPage; loop++) {
      const soalQueryResult = await GetListSoalQuery({
        mataPelajaran: "",
        indikator: "",
        page: loop + 1,
        paging: PAGE_SIZE,
        token: user.accessToken,
        userId: user.uid,
        includeAlokasi: true,
      });

      if (!allSoal.some((x) => x.page === loop + 1)) {
        allSoal.push({
          page: loop + 1,
          soals: get(soalQueryResult, "data.result.Soals", []),
        });
      }

      setAllSoalData([...allSoal]);
    }

    // console.log("allSoal", allSoal);
  }, []);

  useEffect(() => {
    if (
      getPagingStatusData &&
      getPagingStatusData.result &&
      getPagingStatusData.result.JumlahSoal > 0
    ) {
      // console.log("useEffect getPagingStatusData", getPagingStatusData);
      fetchAllData(getPagingStatusData.result).catch(console.error);
    }
  }, [getPagingStatusData]);

  useEffect(() => {
    let soals = [];
    for (const soalData of allSoalData) {
      soals.push(...soalData.soals);
    }

    // console.log("Fetch again", { soals });
    if (soals.length > 0) {
      const tickets = soals.map((soal) => {
        try {
          var statuses = columns.filter((x) => x.id === soal.Status);
          if (statuses.length < 1) {
            console.warn("Cannot find valid status", { soal });
            // return null;
            statuses.push(columns[0]);
          }

          var priorities = types.filter((x) => x.id === soal.TipeSoal);
          if (priorities.length < 1) {
            console.warn("Cannot find valid priorities/TipeSoal", { soal });
            // return null;
            priorities.push(types[0]);
          }

          // console.log("soal", soal);
          return {
            id: soal.KodeSoal,
            title:
              soal.MataPelajaran === "NULL" ? "UNTITLE" : soal.MataPelajaran,
            description: soal.Indikator,
            status: statuses[0].status,
            priority: priorities[0],
            usersAllocation: soal.AlokasiUsers,
          };
        } catch (er) {
          console.log("Error Happened", { soal });
        }
      });

      // console.log("tickets", tickets);
      setTaskData(tickets);
    }
  }, [allSoalData]);

  const onSearchChange = useCallback((event) => {
    setFilter(event.value);
  }, []);

  const resultTasks = useMemo(() => {
    const filterDesc = {
      logic: "or",
      filters: [
        {
          field: "title",
          operator: "contains",
          value: filter,
        },
        {
          field: "description",
          operator: "contains",
          value: filter,
        },
        {
          field: "id",
          operator: "contains",
          value: filter,
        },
      ],
    };
    return filter ? filterBy(taskData, filterDesc) : taskData;
  }, [filter, taskData]);

  const onChangeHandler = useCallback((event) => {
    console.log("Something change event", { event });
    if (event.type === "column") {
      setColumnsData(event.data);
    } else {
      // New Task has been added.
      if (event.item && event.item.id === undefined) {
        event.item.id = event.data.length + 1;
      }
      setTaskData(event.data);
    }
  }, []);

  return (
    <div className="px-11 mt-6">
      <BlockUI blocking={false} />
      <div className="subtitle">
        <h3>Alur Kerja</h3>
      </div>

      <TaskBoard
        columnData={columnsData}
        column={Column}
        taskData={resultTasks}
        priorities={types}
        onChange={onChangeHandler}
        card={Card}
        style={{
          height: "700px",
        }}
        tabIndex={0}
      >
        <TaskBoardToolbar>
          {/*
          <Button icon="add" onClick={onAddColumn}>
            Add Column
          </Button>
          <span className="k-spacer" />
           */}
          <Input
            placeholder="Search..."
            onChange={onSearchChange}
            value={filter}
          />
        </TaskBoardToolbar>
      </TaskBoard>
    </div>
  );
};
