import { createSlice } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid';

const initialState = {
  drawerExpand: false,
  kriteriaSoal: '',
  kodeKisikisi: '',
  lastActionEvent: '',
  userLevel: 1000,
  roleId: "X",
  notificationStyle: "",
  notificationMessage: "",
  notificationId: "",
  fullWidthMode: false,
  noHeaderMode: false,
}

export const globalStateSlice = createSlice({
  name: 'common-state',
  initialState,
  reducers: {
    drawerExpand: (state, action) => {
      state.drawerExpand = action.payload;
    },
    setUserLevel: (state, action) => {
      state.userLevel = action.payload;
    },
    setRoleId: (state, action) => {
      state.roleId = action.payload;
    },
    showNotification: (state, action) => {
      const { style, message } = action.payload;
      console.log('showNotification reducers global', { style, message });
      state.notificationStyle = style;
      state.notificationMessage = message;
      state.notificationId = uuidv4();
    },
    fullWidthModeEventChanges: (state, action) => {
      state.fullWidthMode = action.payload;
    },
    noHeaderModeEventChanges: (state, action) => {
      state.noHeaderMode = action.payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const { drawerExpand, setUserLevel, setRoleId, showNotification, fullWidthModeEventChanges, noHeaderModeEventChanges } = globalStateSlice.actions

export default globalStateSlice.reducer