import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
import { isNullOrWhiteSpace } from "../../common/generic/GenericFunc";
import { showNotification } from "../../features/globalStateSlice";

export const NOTIFICATION_STYLE = {
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
  INFO: "info",
};

export const WdNotification = () => {
  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState([]);

  const closeNotification = (notification) => {
    console.log("closeNotification", { notification, notifications });

    const newNotifications = notifications.filter(
      (x) => x.id !== notification.id
    );
    setNotifications(newNotifications);
  };

  const notificationMessage = useSelector(
    (state) => state.globalState.notificationMessage
  );
  const notificationStyle = useSelector(
    (state) => state.globalState.notificationStyle
  );
  const notificationId = useSelector(
    (state) => state.globalState.notificationId
  );

  useEffect(() => {
    if (!isNullOrWhiteSpace(notificationMessage)) {
      console.log("WdNotification changes", {
        notificationMessage,
        notificationStyle,
        notificationId,
      });

      notifications.push({
        style: notificationStyle,
        message: notificationMessage,
        id: notificationId,
      });

      const newNotifications = [...notifications];
      setNotifications(newNotifications);

      dispatch(
        showNotification({
          style: "",
          message: "",
        })
      );

      /* setTimeout(() => {
        closeNotification({
          style: notificationStyle,
          message: notificationMessage,
          id: notificationId,
        });
      }, 4000); */
    }
  }, [notificationMessage]);

  // console.log("Wd Notification comp", { notificationMessage, notifications });

  return (
    <NotificationGroup
      style={{
        right: 10,
        bottom: 10,
        alignItems: "flex-start",
        flexWrap: "wrap-reverse",
      }}
    >
      <Fade enter={true} exit={true}>
        {notifications.map((notification, i) => {
          return (
            <Notification
              key={i}
              type={{
                style: notification.style,
                icon: true,
              }}
              closable={true}
              onClose={() => closeNotification(notification)}
            >
              {notification.message}
            </Notification>
          );
        })}
      </Fade>
    </NotificationGroup>
  );
};
