import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Listing } from "../components/soal/Listing";
import { Filter } from "../components/soal/Filter";

export const SoalDaftar = () => {
  const [startFiltering, setStartFiltering] = useState(false);
  const [filterParam, setFilterParam] = useState({});
  const handleStartFiltering = (e) => {
    setFilterParam({
      kodeSoal: kodeSoal,
      mataPelajaran: mataPelajaran,
      indikator: indikator,
    });
    setStartFiltering(!startFiltering);
  };
  const [kodeSoal, setKodeSoal] = useState("");
  const [mataPelajaran, setMataPelajaran] = useState("");
  const [indikator, setIndikator] = useState("");
  const updateKodeSoal = (e) => {
    setKodeSoal(e.target.value);
  };
  const updateMataPelajaran = (e) => {
    setMataPelajaran(e.target.value);
  };
  const updateIndikator = (e) => {
    setIndikator(e.target.value);
  };

  useEffect(() => {
    // console.log('something changes', {kodeSoal, mataPelajaran, indikator});
    const timeOutId = setTimeout(() => handleStartFiltering(true), 1000);
    return () => clearTimeout(timeOutId);
  }, [kodeSoal, mataPelajaran, indikator]);
  
  const isExpand = useSelector((state) => state.globalState.drawerExpand);

  return (
    <div
      className={
        isExpand
          ? "grid-layout-container mt-2 expand"
          : "grid-layout-container mt-2 "
      }
    >
      <GridLayout
        gap={{
          rows: 2,
          cols: 1,
        }}
        rows={[
          {
            height: 250,
          },
          {
            height: "auto",
          },
        ]}
      >
        <GridLayoutItem row={1} col={1} className="grid-item">
          <Filter
            updateKodeSoal={updateKodeSoal}
            updateMataPelajaran={updateMataPelajaran}
            updateIndikator={updateIndikator}
            handleStartFiltering={handleStartFiltering}
          />
        </GridLayoutItem>
        <GridLayoutItem
          row={2}
          col={1}
          className="grid-item clear-bg topbrr mb-5"
        >
          <Listing startFiltering={startFiltering} filterParam={filterParam} />
        </GridLayoutItem>
      </GridLayout>
    </div>
  );
};
