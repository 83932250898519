import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import BaseAvatar from "../../assets/wd.png";
import { Button } from "@progress/kendo-react-buttons";
import { trashIcon, pencilIcon } from "@progress/kendo-svg-icons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import useAuth from "../../common/hooks/useAuth";
import { useDeleteUserMutation } from "../../features/userSlice";
import { NameInitialsAvatar } from "react-name-initials-avatar";

export const renderPhoto = ({ photoURL, displayName }) => {
  if (photoURL && photoURL !== "") {
    return (
      <div
        data={displayName}
        className="photo user"
        title={displayName}
        style={{
          backgroundImage: `url(${photoURL})`,
        }}
      ></div>
    );
  } else {
    return (
      <div className="photo" title={displayName}>
        <NameInitialsAvatar
          name={displayName}
          size="100%"
          bgColor="black"
          textColor="white"
        />
      </div>
    );
  }
};