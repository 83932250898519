import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const soalRevisiSlice = createApi({
  reducerPath: 'soalRevisiApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_API_URL}/soal-revisi` }),
  tagTypes: ['soal_revisi_finder'],
  endpoints: (builder) => ({
    getPagingStatus: builder.query({
      query: ({ paging = 100, token }) => ({
        url: `/search-get-pagination`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: { paging }
      }),
      providesTags: ['soal_revisi_finder'],
    }),
    getListSoal: builder.query({
      query: ({ paging = 100, page = 1, token }) => ({
        url: '/search-get-list',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: { paging, page },
      }),
      invalidatesTags: ['soal_revisi_finder'],
    }),
    updateSoalStatus: builder.mutation({
      query: ({ kodeSoal, status, token }) => ({
        url: '/update-status',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: { kodeSoal, status },
      }),
      invalidatesTags: ['soal_revisi_finder', 'soal_editor'],
    }),
    viewSoalDetailPermission: builder.query({
      query: ({ kodeSoal, token }) => ({
        url: '/check-permission',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: { kodeSoal },
      }),
      providesTags: ['soal_revisi_finder', 'soal_editor'],
    }),
  }),
});

export const {
  useGetPagingStatusQuery,
  useGetListSoalQuery,
  useUpdateSoalStatusMutation,
  useViewSoalDetailPermissionQuery,
  useLazyViewSoalDetailPermissionQuery,
} = soalRevisiSlice;