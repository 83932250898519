import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { TransferCriteria } from "../components/transfer/TransferCriteria";
import { TransferList } from "../components/transfer/TransferList";

export const TransferSoal = () => {
  const isExpand = useSelector((state) => state.globalState.drawerExpand);
  const myLevel = useSelector((state) => state.globalState.userLevel);
  const [cekSoalClicked, setCekSoalClicked] = useState(false);
  const handleCheckSoalClick = () => {
    setCekSoalClicked(!cekSoalClicked);
  };

  const [startFiltering, setStartFiltering] = useState(false);
  const [filterParam, setFilterParam] = useState({});
  const handleStartFiltering = (e) => {
    setFilterParam({
      kodeSoal: kodeSoal,
      mataPelajaran: mataPelajaran,
    });
    setStartFiltering(!startFiltering);
  };
  const [kodeSoal, setKodeSoal] = useState("");
  const [mataPelajaran, setMataPelajaran] = useState("");
  const updateKodeSoal = (e) => {
    setKodeSoal(e.target.value);
  };
  const updateMataPelajaran = (e) => {
    setMataPelajaran(e.target.value);
  };

  useEffect(() => {
    // console.log('something changes', {kodeSoal, mataPelajaran, indikator});
    const timeOutId = setTimeout(() => handleStartFiltering(true), 1000);
    return () => clearTimeout(timeOutId);
  }, [kodeSoal, mataPelajaran]);

  if (myLevel > 100) {
    return (
      <div className="grid-layout-container mt-2 p-8">
        Maaf Anda Tidak deperkenankan melihat halaman ini
      </div>
    );
  }

  return (
    <div
      className={
        isExpand
          ? "grid-layout-container mt-2 expand"
          : "grid-layout-container mt-2"
      }
    >
      <GridLayout
        gap={{
          rows: 2,
          cols: 1,
        }}
        rows={[
          {
            height: 200,
          },
          {
            height: "auto",
          },
        ]}
      >
        <GridLayoutItem row={1} col={1} className="grid-item">
          <TransferCriteria
            checkSoalClick={handleCheckSoalClick}
            updateKodeSoal={updateKodeSoal}
            updateMataPelajaran={updateMataPelajaran}
          />
        </GridLayoutItem>
        <GridLayoutItem
          row={2}
          col={1}
          className="grid-item clear-bg topbrr mb-8"
        >
          <TransferList
            startFiltering={startFiltering}
            filterParam={filterParam}
            // cekSoalClicked={cekSoalClicked}
          />
        </GridLayoutItem>
      </GridLayout>
    </div>
  );
};
