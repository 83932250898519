import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { userSlice } from '../features/userSlice';
import { soalFinderSlice } from '../features/soalFinderSlice';
import { soalRevisiSlice } from '../features/soalRevisiSlice';
import { transferSoalSlice } from '../features/transferSoalSlice';
import { soalEditorSlice } from '../features/soalEditorSlice';
import { alokasiRevisiSlice } from '../features/alokasiRevisiSlice';
import { settingSlice } from '../features/settingSlice';
import globalStateReducers from '../features/globalStateSlice';

export const store = configureStore({
  reducer: {
    [userSlice.reducerPath]: userSlice.reducer,
    [soalFinderSlice.reducerPath]: soalFinderSlice.reducer,
    [soalRevisiSlice.reducerPath]: soalRevisiSlice.reducer,
    [transferSoalSlice.reducerPath]: transferSoalSlice.reducer,
    [soalEditorSlice.reducerPath]: soalEditorSlice.reducer,
    [alokasiRevisiSlice.reducerPath]: alokasiRevisiSlice.reducer,
    [settingSlice.reducerPath]: settingSlice.reducer,
    globalState: globalStateReducers,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      // .concat(validationServerSlice.middleware)
      // .concat(reportGeneratorSlice.middleware)
      .concat(userSlice.middleware)
      .concat(transferSoalSlice.middleware)
      .concat(soalEditorSlice.middleware)
      .concat(soalFinderSlice.middleware)
      .concat(soalRevisiSlice.middleware)
      .concat(alokasiRevisiSlice.middleware)
      .concat(transferSoalSlice.middleware)
      .concat(settingSlice.middleware),
  devTools: true,
});

setupListeners(store.dispatch);
