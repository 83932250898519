import { cloneElement } from "react";
import useAuth from "../../common/hooks/useAuth";
import { skipToken } from "@reduxjs/toolkit/query";
import { get } from "lodash";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { renderPhoto } from "../custom-control/custom-component";
import { useGetAllUsersQuery } from "../../features/userSlice";
// import { columns } from "../soal-revisi-kanban/settings";

export const CellRender = (props) => {
  const dataItem = props.originalProps.dataItem;
  const cellField = props.originalProps.field;
  const inEditField = dataItem[props.editField || ""];
  const additionalProps =
    cellField && cellField === inEditField
      ? {
          ref: (td) => {
            const input = td && td.querySelector("input");
            const activeElement = document.activeElement;
            if (
              !input ||
              !activeElement ||
              input === activeElement ||
              !activeElement.contains(input)
            ) {
              return;
            }
            if (input.type === "checkbox") {
              input.focus();
            } else {
              input.select();
            }
          },
        }
      : {
          onClick: () => {
            props.enterEdit(dataItem, cellField);
          },
        };
  const clonedProps = {
    ...props.td.props,
    ...additionalProps,
  };
  const childNodes = props.td.props.children;
  return cloneElement(props.td, clonedProps, childNodes);
};

export const RowRender = (props) => {
  const dataItem = props.originalProps.dataItem;
  const trProps = {
    ...props.tr.props,
    onBlur: () => {
      setTimeout(() => {
        const activeElement = document.activeElement;
        if (
          activeElement &&
          activeElement.className.indexOf("k-calendar") < 0
        ) {
          props.exitEdit(dataItem);
        }
      });
    },
  };
  const childNodes = props.tr.props.children;
  return cloneElement(
    props.tr,
    {
      ...trProps,
    },
    childNodes
  );
};

export const UserRoleSelector = ({ columnId, userId }) => {
  // BELUM KELAR CONTROL INI, PUSING DULUAN
  const { user } = useAuth();
  const { data: usersData } = useGetAllUsersQuery(
    user.accessToken ? { currPage: -1, token: user.accessToken } : skipToken
  );

  const itemRender = (li, itemProps) => {
    // console.log("item X Render", { itemProps, li });
    const itemChildren = (
      <div className="user-item-list">
        {renderPhoto({
          photoURL: itemProps.dataItem.photoURL,
          displayName: itemProps.dataItem.displayName,
        })}
        <span className="user-name">{itemProps.dataItem.displayName}</span>
      </div>
    );
    return cloneElement(li, li.props, itemChildren);
  };

  const valueRender = (element, selectedValue, column) => {
    if (selectedValue) {
      return (
        <div className="user-item-list k-input-inner">
          {renderPhoto({
            photoURL: selectedValue.photoURL,
            displayName: selectedValue.displayName,
          })}
          <span className="user-name">{selectedValue.displayName}</span>
        </div>
      );
    } else {
      return (
        <span className="k-input-inner">
          <input placeholder="Please select ..." value="" />
        </span>
      );
    }
  };

  const users = [];
  let selectedUser = {};
  const column = {};

  for (const userData of get(usersData, "result.users", [])) {
    const usersInRole = column.allowedRoles.filter(
      (x) => x === get(userData, "role.id", "X")
    );
    if (usersInRole.length > 0) {
      users.push({ ...userData });
    }
  }

  <ComboBox
    data={users}
    itemRender={itemRender}
    allowCustom={true}
    textField="displayName"
    dataItemKey="uid"
    value={userId}
    valueRender={(element) => valueRender(element, userId, column)}
    placeholder="Please select ..."
    style={{
      width: "98%",
    }}
  />;
};
