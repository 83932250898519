import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { SoalBaruFullComponent } from "../components/exam/SoalBaruFullComponent";
import { ExamFullDetail } from "../components/exam/ExamFullDetail";
import { showNotification } from "../features/globalStateSlice";
import { NOTIFICATION_STYLE } from "../components/generic/wd-notification";

export const TestPage = () => {
  const dispatch = useDispatch();
  const isExpand = useSelector((state) => state.globalState.drawerExpand);
  const [visible, setVisible] = useState(false);
  const [kodeSoal, setKodeSoal] = useState("");
  const handleToggleDialog = (kodeSoal) => {
    setKodeSoal(kodeSoal);
    setVisible(!visible);
  };

  const notificationMessage = useSelector(
    (state) => state.globalState.notificationMessage
  );
  const handleShowNotification = (notifikasi) => {
    dispatch(showNotification(notifikasi));
  };

  // console.log("Debug Soal baru", { notificationMessage });

  return (
    <div
      className={
        isExpand
          ? "grid-layout-container mt-2 expand"
          : "grid-layout-container mt-2"
      }
    >
      <GridLayout
        gap={{
          rows: 1,
          cols: 1,
        }}
        rows={[
          {
            height: 750,
          },
        ]}
      >
        <GridLayoutItem row={1} col={1} className="grid-item">
          <Button onClick={() => handleToggleDialog("TES-SOAL-001")}>
            Open Soal 001
          </Button>
          <br />
          <Button onClick={() => handleToggleDialog("SOAL_BARU")}>
            Open Soal Baru
          </Button>
          <br />
          <br />
          <hr />
          <br />
          <Button
            onClick={() =>
              handleShowNotification({
                style: NOTIFICATION_STYLE.SUCCESS,
                message: "Test show notification",
              })
            }
          >
            Open Notifikasi
          </Button>
          <Button
            onClick={() =>
              handleShowNotification({
                style: NOTIFICATION_STYLE.ERROR,
                message: "Test error notification",
              })
            }
          >
            Open Notifikasi 2
          </Button>
          - {notificationMessage}
          {visible && (
            <ExamFullDetail
              kodeSoal={kodeSoal}
              toggleDialog={handleToggleDialog}
            />
          )}
        </GridLayoutItem>
      </GridLayout>
    </div>
  );
};
