import { useEffect, useState } from "react";
import { get } from "lodash";
import { skipToken } from "@reduxjs/toolkit/query";
import useAuth from "../../common/hooks/useAuth";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { NumberCell } from "../custom-control/custom-cells";
import { CellRender, RowRender, UserRoleSelector } from "./CustomCellRenderer";
import { MultiSelectDropdownCell } from "./CustomCells";
import { useGetAllUsersQuery } from "../../features/userSlice";
import { Assignee } from "../exam/Assignee";

const editField = "inEdit";

export const BatchUploadListing = ({ batchSoals, handleUploadClick }) => {
  const { user } = useAuth();
  // soal and user selection
  const [isDirty, setDirty] = useState(false);
  const [soals, setSoals] = useState(get(batchSoals, "result"));
  const [selectedUsers, setSelectedUsers] = useState([]);

  const {
    data: getUsersData,
    isFetching: getUsersLoading,
    isSuccess: isGetUsersSuccess,
    isError: isGetUsersError,
    error: getUsersError,
  } = useGetAllUsersQuery(
    user.accessToken ? { token: user.accessToken } : skipToken
  );

  useEffect(() => {
    const tempBatchSoals = get(batchSoals, "result");
    const tempUsers = get(getUsersData, "result.users", []);

    if (tempBatchSoals) {
      const newData = tempBatchSoals.Result.map((item) => {
        var emails = item.Users;
        var uids = [];

        if (tempUsers) {
          uids = tempUsers
            .filter((tmpUser) => emails.includes(tmpUser.email))
            .map((tmpUser) => tmpUser.uid);
        }

        var result = {
          ...item,
          users: tempUsers,
          selectedusers: uids,
          [editField]: undefined,
        };

        return result;
      });
      setDirty(true);
      setSoals(newData);
    }
  }, [batchSoals, getUsersData]);

  const itemChange = (event) => {
    const values = event.value;
    const uids = values.map((item) => item.uid);

    setSelectedUsers(uids);
    setDirty(true);
  };

  const customCellRender = (td, props) => (
    <CellRender
      originalProps={props}
      td={td}
      enterEdit={enterEdit}
      editField={editField}
      users={get(getUsersData, "result.users", [])}
    />
  );

  const customRowRender = (tr, props) => (
    <RowRender
      originalProps={props}
      tr={tr}
      exitEdit={exitEdit}
      editField={editField}
      users={get(getUsersData, "result.users", [])}
    />
  );

  const assigneeChange = (event, kodeSoal) => {
    // console.log("assignee Change", { event, kodeSoal });

    const newData = soals.map((item) => {
      if (item.KodeSoal === kodeSoal) {
        const selectedUsers = [];
        for (const user of event.data) {
          selectedUsers.push(user.uid);
        }

        return {
          ...item,
          selectedusers: selectedUsers,
          [editField]: undefined,
        };
      }

      return {
        ...item,
        [editField]: undefined,
      };
    });
    setSoals(newData);
  };

  const assigneeCell = (props) => {
    // console.log("assigneeCell dataItem", { dataItem: props.dataItem });
    const userAlloc = [];
    for (var user of props.dataItem.selectedusers) {
      userAlloc.push({ UserId: user });
    }

    return (
      /*
      <UserRoleSelector props={props} />
      */
      <Assignee
        usersAlloc={userAlloc}
        vertical={true}
        assigneeChange={(e) => assigneeChange(e, props.dataItem.KodeSoal)}
      />
    );
  };

  const enterEdit = (dataItem, field) => {
    // console.log("Enter edit called", { dataItem, field });
    setSelectedUsers(dataItem.selectedusers ?? []);
    const newData = soals.map((item) => ({
      ...item,
      users: getUsersData.result.users,
      [editField]: item.KodeSoal === dataItem.KodeSoal ? field : undefined,
    }));
    setSoals(newData);
  };

  const exitEdit = (dataItem) => {
    // console.log("Exit edit called", { dataItem });
    const newData = soals.map((item) => {
      if (item.KodeSoal === dataItem.KodeSoal) {
        return {
          ...item,
          selectedusers: selectedUsers,
          [editField]: undefined,
        };
      }

      return {
        ...item,
        [editField]: undefined,
      };
    });
    setSoals(newData);
    setSelectedUsers([]);
  };

  const handleModifiedSoalClick = async () => {
    if (!isDirty) {
      return;
    }
    const alokasiSoal = [];

    for (var i = 0; i < soals.length; i++) {
      var soal = soals[i];

      if (soal.selectedusers) {
        alokasiSoal.push({
          StatusSoal: soal.StatusSoal,
          KodeSoal: soal.KodeSoal,
          Users: soal.selectedusers.map((item) => ({
            UserId: item,
          })),
        });
      }
    }

    handleUploadClick(alokasiSoal);
  };

  // console.log("all state now", { soals, selectedUsers, isDirty });

  return (
    <div className="px-11 mt-6 pb-6">
      <div className="subtitle">
        <h3>Daftar Soal</h3>
      </div>
      <div>
        {soals && soals.length && (
          <Grid
            data={soals}
            onItemChange={itemChange}
            cellRender={customCellRender}
            rowRender={customRowRender}
            editField={editField}
            className="h-auto"
          >
            <GridColumn
              cells={{ data: NumberCell }}
              title="No."
              width={50}
              editable={false}
            />
            <GridColumn
              field="KodeSoal"
              title="Kode Soal"
              editable={false}
              width={200}
            />

            <GridColumn
              field="MataPelajaran"
              title="Mata Pelajaran"
              width={150}
              editable={false}
            />

            <GridColumn
              field="Indikator"
              title="Indikator"
              width={300}
              editable={false}
            />

            <GridColumn
              field="StatusSoal"
              title="Status"
              width={100}
              editable={false}
            />
            {/*
            <GridColumn
              field="users"
              title="Users"
              cell={MultiSelectDropdownCell}
            />
            */}

            <GridColumn field="users" title="Users" cell={assigneeCell} />
          </Grid>
        )}
      </div>
      <div className="row k-mt-10">
        <div className="col col-12">
          {batchSoals && batchSoals.result && (
            <div className="">
              <Button
                disabled={!isDirty}
                onClick={handleModifiedSoalClick}
                className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md px-5 mx-1"
              >
                Upload
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
