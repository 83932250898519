import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get } from "lodash";
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { skipToken } from "@reduxjs/toolkit/query";
import useAuth from "../../common/hooks/useAuth";
import BlockUI from "../generic/BlockUI";
import { showNotification } from "../../features/globalStateSlice";
import { NOTIFICATION_STYLE } from "../generic/wd-notification";
import TheSpinner from "../generic/TheSpinner";
import {
  useGetPagingStatusQuery,
  useGetListSoalQuery,
  useTransferSoalMutation,
} from "../../features/transferSoalSlice";
import { NumberCell } from "../custom-control/custom-cells";

export const TransferList = ({ startFiltering, filterParam }) => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const PAGE_SIZE = 100;

  const [curentPageValue, setCurrentPageValue] = useState(1);
  const [transferSoal] = useTransferSoalMutation();

  //create transferProgress variable to check if transfer is in progress or not (to block the UI)
  const [transferProgress, setTransferProgress] = useState(false);

  const {
    data: getListSoalData,
    isLoading: getlistSoalLoading,
    isSuccess: isGetListSoalSuccess,
    isError: isGetListSoalError,
    error: getListSoalError,
  } = useGetListSoalQuery(
    user.accessToken && (filterParam.mataPelajaran || filterParam.kodeSoal)
      ? {
          mataPelajaran: filterParam.mataPelajaran,
          kodeSoal: filterParam.kodeSoal,
          page: curentPageValue,
          token: user.accessToken,
        }
      : skipToken
  );

  const {
    data: getPagingStatusData,
    isLoading: getPagingStatusLoading,
    isSuccess: isGetPagingStatusSuccess,
    isError: isGetPagingStatusError,
    error: getPagingStatusError,
  } = useGetPagingStatusQuery(
    user.accessToken && (filterParam.mataPelajaran || filterParam.kodeSoal)
      ? {
          mataPelajaran: filterParam.mataPelajaran,
          kodeSoal: filterParam.kodeSoal,
          token: user.accessToken,
        }
      : skipToken
  );
  /* console.log("Transfer List result", {
    getPagingStatusData,
    cekSoalClicked,
    kriteriaSoalChangesGlobal,
    kodeKisiKisiChangesGlobal,
    buttonActionChangesGlobal,
    getListSoalData,
  }); */

  const pageChange = (event) => {
    const currPage = event.page.skip / PAGE_SIZE + 1;
    setCurrentPageValue(currPage);
    // console.log("page changes", { event: event.page, currPage });
  };

  const handleTransferClick = async () => {
    console.log("handleTransferClick");
    //set transferProgress to true to block the UI
    setTransferProgress(true);

    await transferSoal({
      mataPelajaran: filterParam.mataPelajaran,
      kodeSoal: filterParam.kodeSoal,
      token: user.accessToken,
    });
    //set transferProgress to false to unblock the UI
    setTransferProgress(false);

    dispatch(
      showNotification({
        style: NOTIFICATION_STYLE.SUCCESS,
        message: `Proses transfer soal selesai.`,
      })
    );
  };

  return (
    <div className="px-11 mt-6">
      <BlockUI
        blocking={
          getlistSoalLoading || getPagingStatusLoading || transferProgress
        }
      />
      <div className="subtitle">
        <h3>Daftar Soal</h3>
      </div>
      <div>
        {isGetListSoalSuccess &&
          getListSoalData.result &&
          isGetPagingStatusSuccess &&
          getPagingStatusData &&
          getPagingStatusData.result && (
            <Grid
              data={get(getListSoalData, "result.soals", [])}
              total={get(getPagingStatusData, "result.totalCount", 0)}
              take={PAGE_SIZE}
              skip={(curentPageValue - 1) * PAGE_SIZE}
              pageable={{
                buttonCount: 10,
              }}
              onPageChange={pageChange}
              className="h-auto"
            >
              <GridColumn cells={{ data: NumberCell }} title="No." width={50} />
              <GridColumn field="kodeSoal" title="Kode Soal" width={250} />
              <GridColumn
                field="mataPelajaran"
                title="Mata Pelajaran"
                width={200}
              />
              <GridColumn field="indikator" title="Kisi-kisi" />
            </Grid>
          )}

        {(getlistSoalLoading || getPagingStatusLoading || transferProgress) && (
          <>
            <TheSpinner />
          </>
        )}
      </div>
      <div className="row k-mb-4 k-mt-10 items-center">
        {isGetListSoalSuccess &&
          getListSoalData.result &&
          getPagingStatusData &&
          getPagingStatusData.result && (
            <div className="col text-right ">
              <Button
                onClick={handleTransferClick}
                className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md px-5 mx-1 mb-12"
              >
                Transfer
              </Button>
            </div>
          )}
      </div>
    </div>
  );
};
