import { useLocation } from "react-router";
import { useState, useRef, useEffect, useCallback, createRef } from "react";
import { useNavigate } from "react-router-dom";
import { get } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import {
  saveIcon,
  arrowRotateCwIcon,
  arrowRotateCcwIcon,
  downloadIcon,
} from "@progress/kendo-svg-icons";
import { skipToken } from "@reduxjs/toolkit/query";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Editor, EditorTools, EditorUtils } from "@progress/kendo-react-editor";
import useAuth from "../../common/hooks/useAuth";
import { Input, NumericTextBox, TextArea } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import BlockUI from "../generic/BlockUI";
import {
  useGetSoalAttributeQuery,
  useUpdateFileBlobMutation,
  useUpdateSoalAttributeMutation,
  useRetrieveFileBlobMutation,
  useGetSoalRawHtmlQuery,
  useSaveSoalRawHtmlMutation,
} from "../../features/soalEditorSlice";
import {
  useViewSoalDetailPermissionQuery,
  useLazyViewSoalDetailPermissionQuery,
} from "../../features/soalRevisiSlice";
import { useGetListSoalQuery } from "../../features/soalFinderSlice";
import { isNullOrWhiteSpace } from "../../common/generic/GenericFunc";
import { showNotification } from "../../features/globalStateSlice";
import { useCreateAlokasiSoalUsersMutation } from "../../features/alokasiRevisiSlice";
import { useUpdateSoalStatusMutation } from "../../features/soalRevisiSlice";
import { NOTIFICATION_STYLE } from "../generic/wd-notification";
import { DEFAULT_SOAL_BARU_NAME } from "./ExamFullDetail";
import { Assignee } from "./Assignee";
import { AssignStatus } from "./AssignStatus";
import { AssignStatusButton } from "./AssignStatusButton";

export const SoalBaruFullComponent = ({
  kodeSoal = DEFAULT_SOAL_BARU_NAME,
}) => {
  // kodeSoal = "TES-SOAL-001";
  const {
    Bold,
    Italic,
    Underline,
    Strikethrough,
    Subscript,
    Superscript,
    ForeColor,
    BackColor,
    CleanFormatting,
    AlignLeft,
    AlignCenter,
    AlignRight,
    AlignJustify,
    Indent,
    Outdent,
    OrderedList,
    UnorderedList,
    NumberedList,
    BulletedList,
    Undo,
    Redo,
    FontSize,
    FontName,
    FormatBlock,
    Link,
    Unlink,
    InsertImage,
    ViewHtml,
    InsertTable,
    InsertFile,
    SelectAll,
    Print,
    Pdf,
    TableProperties,
    TableCellProperties,
    AddRowBefore,
    AddRowAfter,
    AddColumnBefore,
    AddColumnAfter,
    DeleteRow,
    DeleteColumn,
    DeleteTable,
    MergeCells,
    SplitCell,
  } = EditorTools;

  const { user } = useAuth();
  const dispatch = useDispatch();
  const [updateFileBlob, { isLoading: isFileOnSave }] =
    useUpdateFileBlobMutation();
  const [updateSoalAtribute, { isLoading: isAttrbuteOnSave }] =
    useUpdateSoalAttributeMutation();
  const [saveSoalRaw, { isLoading: isSoalRawOnSave }] =
    useSaveSoalRawHtmlMutation();
  const [retreiveFileBlob] = useRetrieveFileBlobMutation();
  const [base64File, setBase64File] = useState("");
  const [fileExtension, setFileExtension] = useState("");
  const [soalOnSave, setSoalOnSave] = useState(false);
  const [visiblePopSave, setVisiblePopSave] = useState(false);
  const [pageProgress, setPageProgress] = useState(false);
  const [forceBackToListing, setForceBackToListing] = useState(false);
  const [createAlokasiSoalUsers, { isFetching: createAlokasiSoalUserLoading }] =
    useCreateAlokasiSoalUsersMutation();
  const [updateSoalStatus, { isFetching: updateSoalStatusLoading }] =
    useUpdateSoalStatusMutation();
  const [nextAction, setNextAction] = useState(undefined);
  const [showNextActionPop, setShowNextActionPop] = useState(false);
  const [checkSoalPermission] = useLazyViewSoalDetailPermissionQuery();

  const editorSoal = useRef();
  const inputFile = useRef(null);
  const assigneeRef = useRef();
  // const assignStatusRef = useRef();
  const navigate = useNavigate();

  const checkAssignee = () => {
    const assignee = assigneeRef.current.getAssignee();
    console.log("assignee", { assignee });

    // const action = assignStatusRef.current.getAction();
    // console.log("action", { action });
  };

  //create new variable to render Page Title
  const [pageTitle, setPageTitle] = useState(false);

  //create new variable to render Page Title based on isedit querystring value (1/0)
  const [isEdit, setIsEdit] = useState(false);
  //get isedit querystring value
  const location = useLocation();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const isEditValue = searchParams.get("isedit");
    setIsEdit(isEditValue === "1");
    //set setPageTitle value based on isEditValue
    setPageTitle(
      isEditValue === "1" ? "Perubahan Soal" : "Pembuatan Soal Baru"
    );
  }, [location]);

  const userLevel = useSelector((state) => state.globalState.userLevel);

  const {
    data: soalAttribute,
    isLoading: getSoalAttributeLoading,
    isSuccess: getSoalAttributeSuccess,
  } = useGetSoalAttributeQuery(
    kodeSoal && kodeSoal !== DEFAULT_SOAL_BARU_NAME
      ? { kodeSoal, token: user.accessToken }
      : skipToken
  );

  const {
    data: soalPermission,
    isLoading: checkSoalPermissionLoading,
    isSuccess: checkSoalPermissionSuccess,
  } = useViewSoalDetailPermissionQuery(
    kodeSoal && kodeSoal !== DEFAULT_SOAL_BARU_NAME
      ? { kodeSoal, token: user.accessToken }
      : skipToken
  );

  const {
    data: soalFinderAttribute,
    isLoading: getSoalFinderAttributeLoading,
    isSuccess: getSoalFinderAttributeSuccess,
  } = useGetListSoalQuery(
    kodeSoal && kodeSoal !== DEFAULT_SOAL_BARU_NAME
      ? { kodeSoal, token: user.accessToken, includeAlokasi: true }
      : skipToken
  );

  const {
    data: soalHtml,
    // isLoading: getSoalHtmlLoading,
    isSuccess: getSoalHtmlSuccess,
  } = useGetSoalRawHtmlQuery(
    kodeSoal && kodeSoal !== DEFAULT_SOAL_BARU_NAME
      ? { kodeSoal, token: user.accessToken }
      : skipToken
  );

  const handleDownloadClick = async () => {
    // console.log("start download");
    setPageProgress(true);

    await retreiveFileBlob({
      kodeSoal: kodeSoal,
      fileName: `${kodeSoal}.docx`,
      token: user.accessToken,
    });

    setPageProgress(false);
  };

  const handleSaveClick = async () => {
    setPageProgress(true);
    setSoalOnSave(true);
    const attribute = {
      kodeSoal: kodeSoalValue,
      mataPelajaran: mataPelajaranValue,
      bentukSoal: bentukSoalValue,
      status: 0, //statusValue,
      kunci: kunciValue,
      tipeSoal: "1", //tipeSoalValue,
      kompetensi: kompetensiValue,
      subKompetensi: subKompetensiValue,
      indikator: indikatorValue,
      tingkatKesulitan: tingkatKesulitanValue,
      levelKognitif: levelKognitifValue,
      kelas: kelasValue,
      teknologi: teknologiValue,
      konten: kontenValue,
    };

    if (
      isNullOrWhiteSpace(kodeSoalValue) ||
      kodeSoalValue === DEFAULT_SOAL_BARU_NAME
    ) {
      dispatch(
        showNotification({
          style: NOTIFICATION_STYLE.ERROR,
          message: `Kode Soal Invalid`,
        })
      );
      setSoalOnSave(false);
      return;
    }

    if (kodeSoal !== DEFAULT_SOAL_BARU_NAME) {
      const result = await checkSoalPermission({
        kodeSoal,
        token: user.accessToken,
      });

      if (get(result, "data.result", false) === false) {
        setSoalOnSave(false);
        setPageProgress(false);
        setForceBackToListing(true);
        return;
      }
    }

    if (!isNullOrWhiteSpace(base64File)) {
      // console.log("Masuk ke save from upload", { base64File });
      await updateFileBlob({
        kodeSoal,
        serializedWord: base64File ? base64File.split(",")[1] : undefined,
        token: user.accessToken,
      });
    } else {
      // console.log("Masuk ke save from editor", { kodeSoal });
      await saveSoalFromEditor();
    }

    await updateSoalAtribute({ attribute, token: user.accessToken });

    // save assignee conditional (should be same with rendered control)
    if (userLevel <= 100) {
      const assignees = assigneeRef.current.getAssignee();
      const users = [];
      for (const assignee of assignees) {
        users.push({
          UserId: assignee.uid,
        });
      }

      await createAlokasiSoalUsers({
        alokasiSoal: [
          {
            KodeSoal: kodeSoal,
            Users: users,
          },
        ],
        token: user.accessToken,
      });
    }

    /*
    // save status conditional (should be same with rendered control)
    if (kodeSoal !== DEFAULT_SOAL_BARU_NAME) {
      const action = assignStatusRef.current.getAction();
      if (action && action.nextPhaseColumnId) {
        console.log("action", { action });
        await updateSoalStatus({
          kodeSoal,
          status: action.nextPhaseColumnId,
          token: user.accessToken,
        });
      }
    }
    */

    setSoalOnSave(false);
    setPageProgress(false);

    dispatch(
      showNotification({
        style: NOTIFICATION_STYLE.SUCCESS,
        message: `Soal ${kodeSoalValue} telah disimpan`,
      })
    );

    togglePopSave();
    // window.location.reload(false);
  };

  const saveSoalFromEditor = async () => {
    if (editorSoal && editorSoal.current) {
      const view = editorSoal.current.view;
      const htmlText = EditorUtils.getHtml(view.state);

      /*
      console.log("save soal from editor", {
        kodeSoal: kodeSoalValue,
        content: `<body>${htmlText}</body>`,
      });
      */

      await saveSoalRaw({
        kodeSoal: kodeSoalValue,
        content: `<body>${htmlText}</body>`,
        token: user.accessToken,
      });
    }
  };

  const togglePopSave = () => {
    setVisiblePopSave(!visiblePopSave);
  };

  const clearInput = () => {
    if (inputFile.current) {
      inputFile.current.value = "";
      setBase64File("");
      setFileExtension("");
    }
  };

  const confirmNewSoal = () => {
    setKodeSoalValue("");
    setMataPelajaranValue("");
    setBentukSoalValue("");
    setStatusValue(0);
    setKunciValue("");
    setTipeSoalValue("1");
    setKompetensiValue("");
    setSubKompetensiValue("");
    setIndikatorValue("");
    setTingkatKesulitanValue("");
    setLevelKognitifValue("");
    setKelasValue("");
    setTeknologiValue("");
    setKontenValue("");
    setBase64File("");

    togglePopSave();
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    // console.log("handleFileInputChange", { file });

    if (file.size > 5242835) {
      alert("file terlalu besar!");
      return;
    }

    getBase64(file)
      .then((result) => {
        // console.log("get base64", result);
        setBase64File(result);
        setFileExtension(file.name.split(".").pop());
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const toggleActionsPop = (showPopup = false, nextAction = undefined) => {
    // console.log("toggleActionsPop", { showPopup, nextAction });

    if (showPopup === true && nextAction && nextAction.data) {
      setNextAction(nextAction.data);
      setShowNextActionPop(true);
    } else {
      setNextAction(undefined);
      setShowNextActionPop(false);
    }
  };

  const assignStatusClick = async () => {
    if (kodeSoal !== DEFAULT_SOAL_BARU_NAME) {
      toggleActionsPop(false);
      setPageProgress(true);
      const result = await checkSoalPermission({
        kodeSoal,
        token: user.accessToken,
      });

      /*
        console.log("assign Status Click", {
          nextAction,
          kodeSoal,
          result,
        });
      */

      if (nextAction && get(result, "data.result", false)) {
        // console.log("action", { action: event.data });
        await updateSoalStatus({
          kodeSoal,
          status: nextAction.nextPhaseColumnId,
          token: user.accessToken,
        });
        window.location.reload(false);
      } else {
        setForceBackToListing(true);
      }
      setPageProgress(false);
    }
  };

  const [kodeSoalValue, setKodeSoalValue] = useState("");
  const handleKodeSoalChange = useCallback((event) => {
    setKodeSoalValue(event.value);
  }, []);

  const [mataPelajaranValue, setMataPelajaranValue] = useState("");
  const handleMataPelajaranChange = useCallback((event) => {
    setMataPelajaranValue(event.value);
  }, []);

  const [bentukSoalValue, setBentukSoalValue] = useState("");
  const handleBentukSoalChange = useCallback((event) => {
    setBentukSoalValue(event.value);
  }, []);

  const [statusValue, setStatusValue] = useState(0);
  const handleStatusChange = useCallback((event) => {
    setStatusValue(event.value);
  }, []);

  const [kunciValue, setKunciValue] = useState("");
  const handleKunciChange = useCallback((event) => {
    setKunciValue(event.value);
  }, []);

  const [tipeSoalValue, setTipeSoalValue] = useState("1");
  const handleTipeSoalChange = useCallback((event) => {
    setTipeSoalValue(event.value);
  }, []);

  const [kompetensiValue, setKompetensiValue] = useState("");
  const handleKompetensiChange = useCallback((event) => {
    setKompetensiValue(event.value);
  }, []);

  const [subKompetensiValue, setSubKompetensiValue] = useState("");
  const handleSubKompetensiChange = useCallback((event) => {
    setSubKompetensiValue(event.value);
  }, []);

  const [indikatorValue, setIndikatorValue] = useState("");
  const handleIndikatorChange = useCallback((event) => {
    setIndikatorValue(event.value);
  }, []);

  const [tingkatKesulitanValue, setTingkatKesulitanValue] = useState("");
  const handleTingkatKesulitanChange = useCallback((event) => {
    setTingkatKesulitanValue(event.value);
  }, []);

  const [levelKognitifValue, setLevelKognitifValue] = useState("");
  const handleLevelKognitifChange = useCallback((event) => {
    setLevelKognitifValue(event.value);
  }, []);

  const [kelasValue, setKelasValue] = useState("");
  const handleKelasChange = useCallback((event) => {
    setKelasValue(event.value);
  }, []);

  const [teknologiValue, setTeknologiValue] = useState("");
  const handleTeknologiChange = useCallback((event) => {
    setTeknologiValue(event.value);
  }, []);

  const [kontenValue, setKontenValue] = useState("");
  const handleKontenChange = useCallback((event) => {
    setKontenValue(event.value);
  }, []);

  const [alokasiUsers, setAlokasiUsers] = useState([]);

  useEffect(() => {
    if (
      get(soalFinderAttribute, "result.Soals", []).length > 0 &&
      getSoalFinderAttributeSuccess &&
      soalAttribute &&
      soalAttribute.result &&
      getSoalAttributeSuccess &&
      kodeSoal !== DEFAULT_SOAL_BARU_NAME
    ) {
      // console.log("soalAttr", { soalAttribute,soalFinderAttribute });
      setKodeSoalValue(get(soalAttribute, "result.KodeSoal", ""));
      setMataPelajaranValue(get(soalAttribute, "result.MataPelajaran", ""));
      setBentukSoalValue(get(soalAttribute, "result.BentukSoal", ""));
      setStatusValue(get(soalAttribute, "result.Status", ""));
      setKunciValue(get(soalAttribute, "result.Kunci", ""));
      setTipeSoalValue(get(soalAttribute, "result.TipeSoal", ""));
      setKompetensiValue(get(soalAttribute, "result.Kompetensi", ""));
      setSubKompetensiValue(get(soalAttribute, "result.SubKompetensi", ""));
      setIndikatorValue(get(soalAttribute, "result.Indikator", ""));
      setTingkatKesulitanValue(
        get(soalAttribute, "result.TingkatKesulitan", "")
      );
      setLevelKognitifValue(get(soalAttribute, "result.LevelKognitif", ""));
      setKelasValue(get(soalAttribute, "result.Kelas", ""));
      setTeknologiValue(get(soalAttribute, "result.Teknologi", ""));
      setKontenValue(get(soalAttribute, "result.Konten", ""));
      setAlokasiUsers(
        get(soalFinderAttribute, "result.Soals[0].AlokasiUsers", [])
      );
    }
  }, [soalAttribute, soalFinderAttribute]);

  useEffect(() => {
    // console.log("soalPermission", { soalPermission });
    if (
      kodeSoal !== DEFAULT_SOAL_BARU_NAME &&
      soalPermission &&
      soalPermission.result === false
    ) {
      setForceBackToListing(true);
    }
  }, [soalPermission, checkSoalPermissionLoading, checkSoalPermissionSuccess]);

  // console.log("Soal full Detail data", { soalAttribute });

  const backToListClick = () => {
    setForceBackToListing(true);
    navigate("/home/exam/revisi-soal");
    window.location.reload();
  };

  return (
    <div>
      <BlockUI
        blocking={
          (!getSoalHtmlSuccess && kodeSoal !== DEFAULT_SOAL_BARU_NAME) ||
          pageProgress ||
          forceBackToListing
        }
      />
      <div className="container">
        <div className="row p-2">
          <div className="col">
            <div className="row items-center">
              <div className="subtitle">
                <div>
                  <h3>{pageTitle}</h3>
                </div>
              </div>
            </div>

            <div className="row k-mb-4 items-center">
              <div className="col col-2">
                <div className="k-label">Kode Soal</div>
              </div>
              <div className="col col-10">
                <Input
                  value={kodeSoalValue}
                  onChange={handleKodeSoalChange}
                  disabled={
                    userLevel >= 100 || kodeSoal !== DEFAULT_SOAL_BARU_NAME
                  }
                />
              </div>
            </div>

            <div className="row k-mb-4 items-center">
              <div className="col col-2">
                <div className="k-label">Mata Pelajaran</div>
              </div>
              <div className="col col-10">
                <Input
                  value={mataPelajaranValue}
                  onChange={handleMataPelajaranChange}
                  disabled={userLevel >= 100}
                />
              </div>
            </div>

            <div className="row k-mb-4 items-center">
              <div className="col col-2">
                <div className="k-label">Bentuk Soal</div>
              </div>
              <div className="col col-10">
                <Input
                  value={bentukSoalValue}
                  onChange={handleBentukSoalChange}
                  disabled={userLevel >= 100}
                />
              </div>
            </div>

            {/* <div className="row k-mb-4 items-center">
              <div className="col col-4">
                <div className="k-label">Status</div>
              </div>
              <div className="col col-8">
                <NumericTextBox
                  value={statusValue}
                  onChange={handleStatusChange}
                  type={Number}
                  disabled={userLevel >= 100}
                />
              </div>
            </div> */}

            <div className="row k-mb-4 items-center">
              <div className="col col-2">
                <div className="k-label">Kunci</div>
              </div>
              <div className="col col-10">
                <Input value={kunciValue} onChange={handleKunciChange} />
              </div>
            </div>

            {/* <div className="row k-mb-4 items-center">
              <div className="col col-4">
                <div className="k-label">Tipe Soal</div>
              </div>
              <div className="col col-8">
                <Input
                  value={tipeSoalValue}
                  onChange={handleTipeSoalChange}
                  disabled={userLevel >= 100}
                />
              </div>
            </div> */}

            <div className="row k-mb-4 items-center">
              <div className="col col-2">
                <div className="k-label">Kompetensi</div>
              </div>
              <div className="col col-10">
                <TextArea
                  value={kompetensiValue}
                  onChange={handleKompetensiChange}
                  disabled={userLevel >= 100}
                />
              </div>
            </div>

            <div className="row k-mb-4 items-center">
              <div className="col col-2">
                <div className="k-label">Sub Kompetensi</div>
              </div>
              <div className="col col-10">
                <TextArea
                  value={subKompetensiValue}
                  onChange={handleSubKompetensiChange}
                  disabled={userLevel >= 100}
                />
              </div>
            </div>

            <div className="row k-mb-4 items-center">
              <div className="col col-2">
                <div className="k-label">Indikator</div>
              </div>
              <div className="col col-10">
                <TextArea
                  value={indikatorValue}
                  onChange={handleIndikatorChange}
                  disabled={userLevel >= 100}
                />
              </div>
            </div>

            <div className="row k-mb-4 items-center">
              <div className="col col-2">
                <div className="k-label">Tingkat Kesulitan</div>
              </div>
              <div className="col col-10">
                <NumericTextBox
                  value={tingkatKesulitanValue}
                  onChange={handleTingkatKesulitanChange}
                  type={Number}
                  disabled={userLevel >= 100}
                />
              </div>
            </div>

            <div className="row k-mb-4 items-center">
              <div className="col col-2">
                <div className="k-label">Level Kognitif</div>
              </div>
              <div className="col col-10">
                <NumericTextBox
                  value={levelKognitifValue}
                  onChange={handleLevelKognitifChange}
                  type={Number}
                  disabled={userLevel >= 100}
                />
              </div>
            </div>

            <div className="row k-mb-4 items-center">
              <div className="col col-2">
                <div className="k-label">Jenjang</div>
              </div>
              <div className="col col-10">
                <NumericTextBox
                  value={kelasValue}
                  onChange={handleKelasChange}
                  type={Number}
                  disabled={userLevel >= 100}
                />
              </div>
            </div>

            <div className="row k-mb-4 items-center">
              <div className="col col-2">
                <div className="k-label">Materi</div>
              </div>
              <div className="col col-10">
                <Input
                  value={teknologiValue}
                  onChange={handleTeknologiChange}
                  disabled={userLevel >= 100}
                />
              </div>
            </div>

            <div className="row k-mb-4 items-center">
              <div className="col col-2">
                <div className="k-label">Konten</div>
              </div>
              <div className="col col-10">
                <Input
                  value={kontenValue}
                  onChange={handleKontenChange}
                  disabled={userLevel >= 100}
                />
              </div>
            </div>

            {userLevel <= 100 && (
              <div className="row k-mb-4 items-center">
                <div className="col col-2">
                  <div className="k-label">Penugasan</div>
                </div>
                <div className="col col-10">
                  <Assignee ref={assigneeRef} usersAlloc={alokasiUsers} />
                </div>
              </div>
            )}

            {/*
            {kodeSoal !== DEFAULT_SOAL_BARU_NAME && (
              <div className="row k-mb-4 items-center">
                <div className="col col-2">
                  <div className="k-label">Proses selanjutnya</div>
                </div>
                <div className="col col-10">
                  <AssignStatus
                    ref={assignStatusRef}
                    soalAttr={get(soalAttribute, "result")}
                    usersAlloc={alokasiUsers}
                    refreshProps={visiblePopSave}
                    soalAttribute={soalAttribute}
                  />
                </div>
              </div>
            )}
              */}

            {kodeSoal !== DEFAULT_SOAL_BARU_NAME &&
              soalHtml &&
              soalHtml.result && (
                <>
                  <div className="row k-mb-4 items-center">
                    <div className="col col-2">
                      <div className="k-label">Soal Kontent</div>
                    </div>
                    <div className="col col-10">
                      <Editor
                        tools={[
                          [Bold, Italic, Underline, Strikethrough],
                          [Subscript, Superscript],
                          ForeColor,
                          BackColor,
                          [CleanFormatting],
                          [AlignLeft, AlignCenter, AlignRight, AlignJustify],
                          [Indent, Outdent],
                          [OrderedList, UnorderedList],
                          [NumberedList, BulletedList],
                          FontSize,
                          FontName,
                          FormatBlock,
                          [SelectAll],
                          [Undo, Redo],
                          [Link, Unlink, InsertImage, ViewHtml],
                          [InsertTable, InsertFile],
                          [Pdf, Print],
                          [TableProperties, TableCellProperties],
                          [
                            AddRowBefore,
                            AddRowAfter,
                            AddColumnBefore,
                            AddColumnAfter,
                          ],
                          [DeleteRow, DeleteColumn, DeleteTable],
                          [MergeCells, SplitCell],
                        ]}
                        contentStyle={{
                          height: "50vh",
                        }}
                        defaultContent={soalHtml.result}
                        ref={editorSoal}
                      />
                    </div>
                  </div>
                  {/*
                  <div className="row k-mb-4 items-center">
                    <div className="col col-10"></div>
                    <div className="col col-2">
                      <Button
                        svgIcon={soalOnSave ? arrowRotateCwIcon : saveIcon}
                        className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md w-100"
                        onClick={handleSaveSoalClick}
                        disabled={soalOnSave}
                      >
                        Simpan Soal
                      </Button>
                    </div>
                  </div>
                  */}
                </>
              )}

            <div className="row k-mb-4 items-center">
              <div className="col col-2">
                <div className="k-label">Upload Soal</div>
              </div>
              <div className="col col-4">
                <input
                  className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md w-full"
                  type="file"
                  name="file"
                  ref={inputFile}
                  onChange={handleFileInputChange}
                  accept=".doc,.docx"
                />
              </div>
              <div className="col col-1 p-0">
                <Button
                  svgIcon={arrowRotateCcwIcon}
                  onClick={clearInput}
                  className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md ml-2"
                ></Button>
              </div>
            </div>

            {userLevel <= 100 && kodeSoal !== DEFAULT_SOAL_BARU_NAME && (
              <div className="row k-mb-4 items-center">
                <div className="col col-2">
                  <div className="k-label">Download Soal</div>
                </div>
                <div className="col col-4">
                  <Button
                    svgIcon={downloadIcon}
                    onClick={handleDownloadClick}
                    className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md px-5 w-full"
                  >
                    Download
                  </Button>
                </div>
              </div>
            )}

            <hr className="full-width" />
            <div className="row k-mb-4 mt-6 pb-5 items-left">
              <div className="col text-right cta-button">
                {kodeSoal !== DEFAULT_SOAL_BARU_NAME && (
                  <AssignStatusButton
                    soalAttr={get(soalAttribute, "result")}
                    assignStatusClick={(evt) => toggleActionsPop(true, evt)}
                  />
                )}
                <Button
                  svgIcon={soalOnSave ? arrowRotateCwIcon : saveIcon}
                  className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md"
                  onClick={handleSaveClick}
                  disabled={soalOnSave}
                >
                  Simpan
                </Button>

                {/*
                <Button
                  svgIcon={soalOnSave ? arrowRotateCwIcon : saveIcon}
                  className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md"
                  onClick={checkAssignee}
                >
                  Check
                </Button>
                */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {visiblePopSave && !isEdit && (
        <Dialog title={" "} onClose={togglePopSave}>
          <p
            style={{
              margin: "25px",
              textAlign: "center",
            }}
          >
            Soal dengan kode {kodeSoalValue} telah disimpan, Anda ingin membuat
            soal baru?
          </p>
          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={confirmNewSoal}
            >
              Ya
            </button>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={togglePopSave}
            >
              Tidak
            </button>
          </DialogActionsBar>
        </Dialog>
      )}

      {forceBackToListing && (
        <Dialog title={" "}>
          <p
            style={{
              margin: "25px",
              textAlign: "center",
            }}
          >
            Maaf, saat ini anda tidak bisa mengakses soal ini
          </p>
          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={backToListClick}
            >
              Kembali ke List
            </button>
          </DialogActionsBar>
        </Dialog>
      )}

      {showNextActionPop && (
        <Dialog title={" "} onClose={toggleActionsPop}>
          <p
            style={{
              margin: "25px",
              textAlign: "center",
            }}
          >
            Soal ini akan di{nextAction.nextPhaseLabel}. <br />
            <br />
            Proses ?
          </p>
          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={assignStatusClick}
            >
              Ya
            </button>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={toggleActionsPop}
            >
              Tidak
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
    </div>
  );
};
