import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Listing } from "../components/user/Listing";
import { Filter } from "../components/user/Filter";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";

export const UserSearch = () => {
  const isExpand = useSelector((state) => state.globalState.drawerExpand);
  const myLevel = useSelector((state) => state.globalState.userLevel);

  const [startFiltering, setStartFiltering] = useState(false);
  const [filterParam, setFilterParam] = useState({});

  const handleStartFiltering = (e) => {
    console.log("Steart filtering", { keywords });
    setFilterParam({
      keywords: keywords,
    });
    setStartFiltering(!startFiltering);
  };
  const [keywords, setKeywords] = useState("");
  const updateKeywords = (e) => {
    setKeywords(e.target.value);
  };

  useEffect(() => {
    // console.log("something changes keywords", { keywords });
    if (keywords.length > 3 || keywords.length === 0) {
      const timeOutId = setTimeout(() => handleStartFiltering(true), 1000);
      return () => clearTimeout(timeOutId);
    }
  }, [keywords]);

  if (myLevel > 100) {
    return (
      <div className="grid-layout-container mt-2 p-8">
        Maaf Anda Tidak deperkenankan melihat halaman ini
      </div>
    );
  }

  return (
    <div
      className={
        isExpand
          ? "grid-layout-container mt-2 p-8 mb-5 expand"
          : "grid-layout-container mt-2 p-8 mb-5"
      }
    >
      <GridLayout
        gap={{
          rows: 2,
          cols: 5,
        }}
        rows={[
          {
            height: 150,
          },
          {
            height: "auto",
          },
        ]}
      >
        <GridLayoutItem row={1} col={1} className="grid-item">
          <Filter updateKeywords={updateKeywords} />
        </GridLayoutItem>
        <GridLayoutItem row={2} col={1} className="grid-item">
          <Listing startFiltering={startFiltering} filterParam={filterParam} />
        </GridLayoutItem>
      </GridLayout>
    </div>
  );
};
