import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import BaseAvatar from "../../assets/wd.png";
import { Button } from "@progress/kendo-react-buttons";
import { trashIcon, pencilIcon } from "@progress/kendo-svg-icons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import useAuth from "../../common/hooks/useAuth";
import { useDeleteUserMutation } from "../../features/userSlice";

export const PhotoCell = ({ dataItem, ...props }) => {
  if (!dataItem || !dataItem.photoURL) {
    return (
      <td {...props.tdProps}>
        <div
          style={{ backgroundImage: `url(${BaseAvatar})` }}
          className="w-12 h-12 rounded-full bg-cover bg-black"
        ></div>
      </td>
    );
  }
  const imageDataUrl = dataItem.photoURL.replace(/url\('(.*)'\)/, "$1");
  return (
    <td {...props.tdProps}>
      <div
        style={{ backgroundImage: `url(${imageDataUrl})` }}
        className="w-12 h-12 rounded-full bg-cover bg-black"
      ></div>
    </td>
  );
};

export const ActionCell = ({ dataItem, ...props }) => {
  const [visible, setVisible] = React.useState(false);
  const [removeUser] = useDeleteUserMutation();
  const { user } = useAuth();
  let navigate = useNavigate();
  const toggleDialog = (uid) => {
    if (uid === dataItem.uid) {
      setVisible(!visible);
    }
  };
  const myLevel = useSelector((state) => state.globalState.userLevel);
  // console.log('Actioan Cell', {dataItem, myLevel});

  const confirmRemove = (uid) => {
    removeUser({ id: uid, token: user.accessToken });
    setVisible(!visible);
  };

  return (
    <>
      <td {...props.tdProps}>
        <div className="w-12 h-12 flex flex-row">
          <Button
            className="action-btn"
            svgIcon={pencilIcon}
            onClick={() => navigate(`/home/manage-user/${dataItem.uid}`)}
            disabled={dataItem.role.level < myLevel}
          ></Button>
          <Button
            className="action-btn"
            svgIcon={trashIcon}
            onClick={() => toggleDialog(dataItem.uid)}
            disabled={dataItem.role.level < myLevel}
          ></Button>
        </div>
      </td>
      {visible && (
        <Dialog title={" "} onClose={() => toggleDialog(dataItem.uid)}>
          <p
            style={{
              margin: "25px",
              textAlign: "center",
            }}
          >
            Are you sure you want to delete {dataItem.email}?
          </p>
          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={() => toggleDialog(dataItem.uid)}
            >
              No
            </button>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={() => confirmRemove(dataItem.uid)}
            >
              Yes
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
    </>
  );
};

export const NumberCell = ({ dataItem, ...props }) => {
  return (
    <td {...props.tdProps}>
      <div className="">{props.dataIndex + 1}.</div>
    </td>
  );
};

export const StatusCell = ({ dataItem, ...props }) => {
  if (dataItem && dataItem.disabled) {
    return (
      <td {...props.tdProps}>
        <div className="flex justify-center">
          <span className="k-icon k-font-icon k-i-cancel k-icon-xl text-red-500"></span>
        </div>
      </td>
    );
  }

  return (
    <td {...props.tdProps}>
      <div className="flex justify-center">
        <span className="k-icon k-font-icon k-i-check-outline k-icon-xl text-green-500"></span>
      </div>
    </td>
  );
};
