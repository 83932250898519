export const isNullOrEmpty = (input) => {
    return (input === undefined || input === null || input === "");
}

export const isNullOrWhiteSpace = (input) => {
    if (input === undefined || input === null || input === "") {
        return true;
    }

    return /\s/.test(
        typeof value === 'number' ? String.fromCodePoint(input) : input.charAt(0)
    )
}