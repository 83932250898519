import { useState, useEffect, useCallback, useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { get } from "lodash";
import useAuth from "../../common/hooks/useAuth";
import { useLazyGetUserQuery } from "../../features/userSlice";
import { columns } from "./settings";
import { NameInitialsAvatar } from "react-name-initials-avatar";

export const PreviewDialog = (props) => {
  const { user } = useAuth();
  const [GetUserQuery] = useLazyGetUserQuery();
  const [userList, setUserList] = useState([]);

  const fetchAllocationUserData = useCallback(async (task) => {
    const userAllocs = [];
    const currentCols = columns.filter((x) => x.status === task.status);
    if (currentCols.length > 0) {
      for (const userAlloc of task.usersAllocation) {
        const userDetail = await GetUserQuery({
          token: user.accessToken,
          id: userAlloc.UserId,
        });

        // console.log("userDetail", { task, userDetail });
        const isUserRoleValid = currentCols[0].allowedRoles.filter(
          (x) => x === get(userDetail, "data.result.user.role.id")
        );

        userAllocs.push({
          id: userAlloc.UserId,
          photoURL: get(userDetail, "data.result.user.photoURL")
            ? get(userDetail, "data.result.user.photoURL")
            : "",
          displayName: get(userDetail, "data.result.user.displayName"),
          roleId: get(userDetail, "data.result.user.role"),
          roleName: get(userDetail, "data.result.user.role.roleName"),
          isRoleValid: isUserRoleValid.length > 0,
        });
      }

      // console.log("userAlloc", { userAllocs });
      setUserList(userAllocs);
    }
  }, []);

  useEffect(() => {
    if (
      props.task &&
      props.task.usersAllocation &&
      props.task.usersAllocation.length > 0
    ) {
      // console.log("sampe nih useEffect", { task: props.task });
      fetchAllocationUserData(props.task);
    }
  }, [props]);

  const renderUsers = () => {
    if (userList.length > 0) {
      // console.log("usersAllocation", { usersAllocation });
      return userList.map((currUser) => {
        // console.log("currUser", { currUser });
        if (currUser.photoURL !== "") {
          return (
            <div className="user-details">
              <div
                key={currUser.id}
                data={currUser.displayName}
                className={currUser.isRoleValid ? "user" : "user cross"}
                title={currUser.displayName}
                style={{
                  backgroundImage: `url(${currUser.photoURL})`,
                }}
              ></div>
              <span className="user-name">{`${currUser.displayName} (${currUser.roleName})`}</span>
            </div>
          );
        } else {
          return (
            <div className="user-details">
              <div
                className={
                  currUser.isRoleValid
                    ? "user user-profile"
                    : "user user-profile cross"
                }
                title={currUser.displayName}
              >
                <NameInitialsAvatar
                  name={currUser.displayName}
                  size="100%"
                  bgColor="black"
                  textColor="white"
                />
              </div>
              <span className="user-name">{`${currUser.displayName} (${currUser.roleName})`}</span>
            </div>
          );
        }
      });
    }

    return <></>;
  };

  // console.log("PreviewDialog props", { props });
  return (
    <div className="k-taskboard-pane k-taskboard-preview-pane">
      <div className="k-taskboard-pane-header">
        <div className="k-taskboard-pane-header-text">{props.title}</div>
        <span className="k-spacer" />
        <div className="k-taskboard-pane-header-actions">
          <Button
            icon="close"
            fillMode="flat"
            onClick={props.onClosePreviewPane}
          />
        </div>
      </div>
      <hr></hr>
      <div className="h-5" />
      <div className="k-taskboard-pane-content">
        <div>
          <div>Deskripsi: &nbsp;</div>
          <span className="ml-2">{props.description}</span>
        </div>

        <div className="h-3" />
        <div>
          <div>Kode Soal: &nbsp;</div>
          <span className="ml-2">{props.task.id}</span>
        </div>

        <div className="h-3" />
        <div>
          <div>Tipe Soal: &nbsp;</div>
          <span
            className="ml-2"
            style={{
              backgroundColor: props.priority.color,
            }}
          >
            &nbsp;
          </span>
          &nbsp;
          {props.priority.priority}
        </div>

        <div className="h-3" />
        <div>
          <div>Penerima Tugas:</div>
          <div className="user-list vertical">{renderUsers()}</div>
        </div>
      </div>

      <div className="k-taskboard-pane-actions k-actions k-hstack k-justify-content-end">
        {/* 
        <Button onClick={props.onTaskDelete}>{props.delete}</Button> 
        <Button themeColor={"primary"} onClick={props.onTaskEdit}>
          {props.edit}
        </Button>
        */}
      </div>
    </div>
  );
};
