import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const soalEditorSlice = createApi({
  reducerPath: 'soalEditorApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_API_URL}/soal` }),
  tagTypes: ['soal_editor'],
  endpoints: (builder) => ({
    getSoalHtml: builder.query({
      query: ({ kodeSoal, token }) => ({
        url: `/get-soal-html/${kodeSoal}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      providesTags: ['soal_editor'],
    }),
    getSoalRawHtml: builder.query({
      query: ({ kodeSoal, token }) => ({
        url: `/get-soal-raw-html/${kodeSoal}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      providesTags: ['soal_editor'],
    }),
    saveSoalRawHtml: builder.mutation({
      query: ({ kodeSoal, content, token }) => ({
        url: `/save-soal-raw-html`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: {
          kodeSoal,
          content
        }
      }),
      invalidatesTags: ['soal_editor'],
    }),
    getPreviewKeys: builder.query({
      query: ({ kodeSoal, token }) => ({
        url: `/get-preview-keys/${kodeSoal}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      providesTags: ['soal_editor'],
    }),
    retrieveFileBlob: builder.mutation({
      queryFn: async ({ kodeSoal, fileName, token }, api, extraOptions, baseQuery) => {
        const result = await baseQuery({
          url: `/download-word/${kodeSoal}`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          method: 'GET',
          cache: 'no-cache',
          responseHandler: ((response) => response.blob())
        })
        var hiddenElement = document.createElement('a');
        var url = window.URL || window.webkitURL;
        var blobData = url.createObjectURL(result.data);
        hiddenElement.href = blobData;
        hiddenElement.target = '_blank';
        hiddenElement.download = fileName;
        hiddenElement.click();
        return { data: null }
      }
    }),
    updateFileBlob: builder.mutation({
      query: ({ kodeSoal, serializedWord, token }) => ({
        url: '/save-word',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: { kodeSoal, serializedWord },
      }),
      invalidatesTags: ['soal_editor'],
    }),
    getSoalAttribute: builder.query({
      query: ({ kodeSoal, token }) => ({
        url: `/get-soal-attribute/${kodeSoal}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      providesTags: ['soal_editor'],
    }),
    updateSoalAttribute: builder.mutation({
      query: ({ attribute, token }) => ({
        url: '/save-soal-attribute',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: attribute,
      }),
      invalidatesTags: ['soal_editor'],
    }),
    previewBatchFileBlob: builder.mutation({
      query: ({ fileName, serializedWord, token }) => ({
        url: '/preview-batch-upload-file',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: { fileName, serializedWord },
      }),
      invalidatesTags: ['soal_editor'],
    }),
    previewAttributeBatchFileBlob: builder.mutation({
      query: ({ fileName, serializedWord, token }) => ({
        url: '/preview-batch-upload-file',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: { fileName, serializedWord },
      }),
      invalidatesTags: ['soal_editor'],
    }),
    uploadBatchFileBlob: builder.mutation({
      query: ({ soals, token }) => ({
        url: '/save-batch-upload-file',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: { soals },
      }),
      invalidatesTags: ['soal_editor'],
    }),
    uploadAttributeBatchFileBlob: builder.mutation({
      query: ({ soals, token }) => ({
        url: '/save-batch-attribute-upload-file',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: { soals },
      }),
      invalidatesTags: ['soal_editor'],
    }),
  }),
});

export const {
  useGetSoalHtmlQuery,
  useGetSoalRawHtmlQuery,
  useSaveSoalRawHtmlMutation,
  useGetPreviewKeysQuery,
  useGetSoalAttributeQuery,
  useRetrieveFileBlobMutation,
  useUpdateFileBlobMutation,
  useUpdateSoalAttributeMutation,
  usePreviewBatchFileBlobMutation,
  usePreviewAttributeBatchFileBlobMutation,
  useUploadBatchFileBlobMutation,
  useUploadAttributeBatchFileBlobMutation,
} = soalEditorSlice;
