import * as React from "react";
import { useSelector } from "react-redux";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import useAuth from "../common/hooks/useAuth";

export const Dashboard = () => {
  const isExpand = useSelector((state) => state.globalState.drawerExpand);
  const { user } = useAuth();

  return (
    <div
      className={
        isExpand ? "grid-layout-container expand" : "grid-layout-container"
      }
    >
      <GridLayout
        gap={{
          rows: 1,
          cols: 1,
        }}
        rows={[
          {
            height: "800",
          },
        ]}
      >
        <GridLayoutItem
          row={1}
          col={1}
          className="grid-item topbrr mb-8"
        >
          <h1 className="p-8">Hello again, {user?.displayName}!</h1>
        </GridLayoutItem>
      </GridLayout>
    </div>
  );
};
