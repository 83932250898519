import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Filter } from "../components/alokasi-soal/Filter";
import { Listing } from "../components/alokasi-soal/Listing";

export const SoalAlokasiRevisi = () => {
  const myLevel = useSelector((state) => state.globalState.userLevel);
  const isExpand = useSelector((state) => state.globalState.drawerExpand);

  const [startFiltering, setStartFiltering] = useState(false);
  const [filterParam, setFilterParam] = useState({});
  const handleStartFiltering = (e) => {
    setFilterParam({
      kodeSoal: kodeSoal,
      mataPelajaran: mataPelajaran,
      indikator: indikator,
    });
    setStartFiltering(!startFiltering);
  };

  const [kodeSoal, setKodeSoal] = useState("");
  const [indikator, setIndikator] = useState("");
  const [mataPelajaran, setMataPelajaran] = useState("");
  const updateKodeSoal = (e) => {
    setKodeSoal(e.target.value);
  };
  const updateIndikator = (e) => {
    setIndikator(e.target.value);
  };
  const updateMataPelajaran = (e) => {
    setMataPelajaran(e.target.value);
  };

  useEffect(() => {
    // console.log('something changes', {kodeSoal, mataPelajaran, indikator});
    const timeOutId = setTimeout(() => handleStartFiltering(true), 1000);
    return () => clearTimeout(timeOutId);
  }, [kodeSoal, mataPelajaran, indikator]);

  if (myLevel > 100) {
    return (
      <div className="grid-layout-container mt-2 p-8">
        Maaf Anda Tidak deperkenankan melihat halaman ini
      </div>
    );
  }

  return (
    <div
      className={
        isExpand
          ? "grid-layout-container mt-2 mb-36 expand"
          : "grid-layout-container mt-2 mb-36"
      }
    >
      <GridLayout
        gap={{
          rows: 2,
          cols: 1,
        }}
        rows={[
          {
            height: 250,
          },
          {
            height: "auto",
          },
        ]}
      >
        <GridLayoutItem row={1} col={1} className="grid-item">
          <Filter
            updateKodeSoal={updateKodeSoal}
            updateIndikator={updateIndikator}
            updateMataPelajaran={updateMataPelajaran}
            handleStartFiltering={handleStartFiltering}
          />
        </GridLayoutItem>
        <GridLayoutItem
          row={2}
          col={1}
          className="grid-item clear-bg topbrr mb-8"
        >
          <Listing startFiltering={startFiltering} filterParam={filterParam} />
        </GridLayoutItem>
      </GridLayout>
    </div>
  );
};
